import React from "react";

import { Loader, Image, Group } from "@mantine/core";
import tw from "twin.macro";

import naSVG from "images/not-available.svg";

const Wrapper = tw.div`container`;

const NotAvailable = ({
  label,
  image = naSVG,
  showLoader = false,
  showThumbnail = true,
  isDark = false,
  fullWidth = false,
  icon = null,
}) => {
  return (
    <Wrapper>
      <div tw="row-auto">
        <div
          css={[
            tw`w-full mx-auto text-center`,
            !fullWidth && tw`md:w-1/2 lg:w-1/3`,
          ]}
        >
          {showThumbnail && <Image src={image} alt="Not Available" />}
          {icon && (
            <Group position="center" mb="sm">
              {icon}
            </Group>
          )}
          <p css={isDark ? tw`text-primary-800` : tw`text-gray-lightest`}>
            {label}
          </p>
          <div tw="flex justify-center mt-2">
            {showLoader && <Loader color="yellow" />}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default NotAvailable;
