import { lazy } from "react";

// use lazy for better code splitting, a.k.a. load faster
const InstitutionApplication = lazy(() => import("pages/institutions/InstitutionApplication"));
const UpdateInstApplication = lazy(() => import("pages/institutions/UpdateInstApplication"));
const ManageInstitution = lazy(() => import("pages/institutions/admin/ManageInstitution"));

const institutionRoutes = [
  {
    path: "/institution-application", // the url
    component: InstitutionApplication, // view rendered
    title: "Institution",
    exact: true,
  },
  {
    path: "/institution-application/A-:institutionID/update", // the url
    component: UpdateInstApplication, // view rendered
    title: "Update Institution",
    exact: true,
    permission: ["Admin", "Client"],
  },
  {
    path: "/manage-institutions/", // the url
    component: ManageInstitution, // view rendered
    title: "Manage Institutions",
    exact: true,
    permission: ["Admin"],
  },
];

export default institutionRoutes;
