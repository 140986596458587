import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import tw from "twin.macro";
import {
  Menu,
  Badge,
  Modal,
  Select,
  Avatar,
  AvatarsGroup,
} from "@mantine/core";
import { AiFillEdit, AiFillDelete, AiFillCalendar } from "react-icons/ai";
import { GrUserWorker, GrLocation } from "react-icons/gr";
import { toast } from "react-toastify";

import NotAvailable from "components/misc/NotAvailable";
import { SaveChangesButton } from "components/misc/Buttons";
import { addUserMeta } from "utils/helpers";
import axiosService from "utils/axios";

// import usePagination from "utils/usePagination";

export const ServiceLocations = ({ locations }) => (
  <AvatarsGroup limit={3} size="sm">
    {locations.map((country) => (
      <Avatar
        key={country.id}
        src={country?.flag_url ?? null}
        color="yellow"
        title={country.name}
        alt={country.name}
      >
        {country.name.slice(0, 3)}
      </Avatar>
    ))}
  </AvatarsGroup>
);

const ManageService = ({
  paginated,
  header,
  prepareUpdate,
  prepareDelete,
  toggleActiveStatus,
}) => {
  const currentUserRoles = useSelector((state) => state.auth.account.role);
  const [showModal, setShowModal] = useState(false);
  const [newStatus, setNewStatus] = useState("");
  const [actionURL, setActionURL] = useState(null);
  const { data: services, mutateData: mutate, pagination } = paginated;

  // services && console.log("Manage Services", services);
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const setupStatusUpdate = (service) => {
    setActionURL(service.api_url);
    setNewStatus(service.status);
    openModal();
  };

  const handleStatusUpdate = () => {
    if (!actionURL || currentUserRoles.includes("Client")) return;
    let data = new FormData();
    data.append("status", newStatus);
    data = addUserMeta(data);
    axiosService
      .patch(actionURL, data)
      .then((_) => {
        toast.info("Status Changed");
        mutate();
        closeModal();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Unable to update!");
      });
  };

  return (
    <div tw="container min-w-full mx-auto rounded p-5">
      {header}
      <div tw="p-5">
        {/* <div>
          <h2 tw="text-2xl font-semibold leading-tight text-white">
            Manage Services
          </h2>
        </div> */}
        <Modal
          centered
          opened={showModal}
          onClose={() => closeModal()}
          title={<span tw="text-xl font-bold">{"Status Update"}</span>}
          transition="fade"
          transitionDuration={500}
        >
          <Select
            label="Please select a new status"
            placeholder="Must pick one..."
            data={[
              { value: "submitted", label: "Submitted" },
              { value: "under_review", label: "Under Review" },
              { value: "approved", label: "Approved" },
              { value: "declined", label: "Declined" },
            ]}
            value={newStatus}
            onChange={setNewStatus}
          />

          <SaveChangesButton
            onClick={() => handleStatusUpdate()}
            tw="mt-3"
            // disabled={newStatus === profile.status}
          />
        </Modal>

        {services &&
          (services?.length > 0 ? (
            <div tw="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
              <div tw="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
                <table tw="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th tw="text-white pr-3">#</th>
                      <th tw="px-5 py-4 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Service
                      </th>
                      <th tw="px-5 py-4 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Details
                      </th>
                      <th tw="px-5 py-4 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Status
                      </th>
                      <th tw="px-5 py-4 border-b-2 border-gray-200 bg-gray-100"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {services?.map((service, index) => (
                      <tr key={index}>
                        <td tw="text-white pr-3">
                          {currentUserRoles.includes("Admin")
                            ? service.order
                            : index + 1}
                        </td>
                        <td tw="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div tw="flex flex-col sm:flex-row items-center">
                            <div tw="flex-shrink-0 w-10 h-10">
                              <Link to={service.preview_url}>
                                <img
                                  tw="w-full h-full rounded object-cover"
                                  src={service.thumbnail}
                                  alt=""
                                />
                              </Link>
                            </div>
                            <div tw="ml-3">
                              <Link
                                to={service.dashboard_url}
                                tw="hover:text-red-500 text-lg whitespace-normal"
                              >
                                {service.title}
                              </Link>
                              <p tw="text-gray-600 whitespace-normal max-w-72 line-clamp-1">
                                {service.description}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td tw="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div tw="flex items-center">
                            <span tw="mr-2">
                              <AiFillCalendar />
                            </span>
                            <p tw="text-gray-900 text-base whitespace-normal ">
                              {service.created_date}
                            </p>
                            <p tw="text-base whitespace-normal hidden sm:block">
                              <span tw="text-gray-600 ml-2">by </span>
                              {service.owner_username}
                            </p>
                          </div>
                          <div tw="flex items-center">
                            <span tw="mr-2">
                              <GrUserWorker color="plain" />
                            </span>
                            <p tw="text-gray-900 text-base">
                              {service.provider}
                            </p>
                          </div>
                          {service.locations.length > 0 && (
                            <div tw="flex items-center uppercase">
                              <span tw="mr-2">
                                <GrLocation color="plain" />
                              </span>
                              <ServiceLocations locations={service.locations} />
                            </div>
                          )}
                        </td>
                        <td tw="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p tw="mb-4">
                            Service:{" "}
                            <span
                              onClick={() =>
                                toggleActiveStatus(
                                  service.is_active,
                                  service.api_url
                                )
                              }
                              aria-hidden
                              css={[
                                service.is_active
                                  ? tw`bg-green-200 hover:bg-red-300`
                                  : tw`bg-red-300 hover:bg-green-200`,
                                tw`font-semibold px-3 py-1 rounded-xl cursor-pointer`,
                              ]}
                            >
                              {service.is_active ? "Active" : "Inactive"}
                            </span>
                          </p>

                          <div tw="flex items-center">
                            <span tw="mr-1">Verification: </span>
                            <Badge
                              onClick={() =>
                                currentUserRoles.includes("Admin") &&
                                setupStatusUpdate(service)
                              }
                              css={
                                currentUserRoles.includes("Admin")
                                  ? tw`cursor-pointer`
                                  : tw`cursor-default`
                              }
                              variant={
                                currentUserRoles.includes("Admin")
                                  ? "filled"
                                  : "outline"
                              }
                              color={
                                service.status === "under_review"
                                  ? "yellow"
                                  : service.status === "approved"
                                  ? "green"
                                  : service.status === "declined"
                                  ? "red"
                                  : "blue"
                              }
                            >
                              {service.status}
                            </Badge>
                          </div>
                        </td>
                        <td tw="px-5 py-5 border-b border-gray-200 bg-white text-sm text-right">
                          <Menu trigger="hover" delay={500}>
                            <Menu.Label>Actions</Menu.Label>
                            <Menu.Item
                              onClick={() => prepareUpdate(service.api_url)}
                              // tw="text-yellow-500"
                              icon={<AiFillEdit size={14} />}
                            >
                              Update
                            </Menu.Item>
                            <Menu.Item
                              onClick={() => prepareDelete(service.api_url)}
                              // tw="text-red-500"
                              icon={<AiFillDelete size={14} />}
                            >
                              Delete
                            </Menu.Item>
                            {/* <Menu.Item icon={<AiFillInfoCircle size={14} />}>
                              Gallery
                            </Menu.Item> */}
                          </Menu>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div tw="flex items-center justify-center mt-3">
                  {pagination}
                </div>
              </div>
            </div>
          ) : (
            <NotAvailable label="You have not added any services yet!" />
          ))}
      </div>
    </div>
  );
};

export default ManageService;
