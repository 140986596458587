import Overlay from "./provider/overlay";
import TopNavigation from "./topnavigation";
import SideNavigation from "./sidenavigation";
import Footer from "./footer";
import DashboardProvider from "./provider/context";
import tw from "twin.macro";

const style = {
  container: tw`bg-gray-900 h-screen overflow-hidden relative`,
  mainContainer: tw`flex flex-col h-screen pl-0 w-full lg:pl-20 lg:space-y-4`,
  main: tw`h-screen overflow-x-hidden overflow-y-auto pb-32 pt-4 px-2 md:pb-8 md:pt-4 lg:pt-0 lg:px-4`,
};

export default function DashboardLayout({ children }) {
  return (
    <DashboardProvider>
      <div css={style.container}>
        <div tw="flex items-start">
          <Overlay />
          <SideNavigation mobilePosition="right" />
          <div css={style.mainContainer}>
            <TopNavigation />
            <main css={style.main}>{children}</main>
            <Footer />
          </div>
        </div>
      </div>
    </DashboardProvider>
  );
}
