import { lazy } from "react";

import serviceCategoryRoutes from "./appRoutes/serviceCategoryRoutes";
import serviceRoutes from "./appRoutes/serviceRoutes";
import profileRoutes from "./appRoutes/profileRoutes";
import blogRoutes from "./appRoutes/blogRoutes";
import locationRoutes from "./appRoutes/locationRoutes";
import institutionRoutes from "./appRoutes/institutionRoutes";
import purchaseRoutes from "./appRoutes/purchaseRoutes";
import projectRoutes from "./appRoutes/projectRoutes";

// use lazy for better code splitting, a.k.a. load faster
const Home = lazy(() => import("../pages/protected/Home"));
const Account = lazy(() => import("../pages/protected/Account"));
const ManageUsers = lazy(() => import("../pages/users/ManageUsers"));
const AddUser = lazy(() => import("../pages/users/AddUser"));
const UpdateUser = lazy(() => import("pages/users/UpdateUser"));
const VerifyEmailChange = lazy(() => import("pages/users/VerifyEmailChange"));
const ZoomAuth = lazy(() => import("pages/protected/ZoomAuth"));
const Notifications = lazy(() => import("pages/protected/Notifications"));
const ManageContact = lazy(() => import("pages/enquiries/ManageContact"));

const protectedRoutes = [
  {
    path: "/app", // the url
    component: Home, // view rendered
    title: "Home",
    exact: true,
  },
  {
    path: "/app/my-account", // the url
    component: Account, // view rendered
    title: "Account",
    exact: true,
  },
  {
    path: "/app/my-account/verify-email-change", // the url
    component: VerifyEmailChange, // view rendered
    title: "Verify Email Change",
  },
  {
    path: "/manage-users", // the url
    component: ManageUsers, // view rendered
    title: "Manage Users",
    permission: ["Admin"],
    exact: true,
  },
  {
    path: "/manage-users/update/user-:userID",
    component: UpdateUser,
    title: "Update User",
    permission: ["Admin"],
    exact: true,
  },
  {
    path: "/manage-users/add", // the url
    component: AddUser, // view rendered
    title: "Add New User",
    permission: ["Admin"],
  },
  {
    path: "/admin-zoom-auth", // the url
    component: ZoomAuth, // view rendered
    title: "Zoom Authorization",
    permission: ["Admin"],
  },
  {
    path: "/notifications", // the url
    component: Notifications, // view rendered
    title: "Notifications",
  },
  {
    path: "/enquiries/contacts", // the url
    component: ManageContact, // view rendered
    title: "Manage Enquiries",
    permission: ["Admin"],
  },

  ...serviceCategoryRoutes,
  ...serviceRoutes,
  ...profileRoutes,
  ...blogRoutes,
  ...locationRoutes,
  ...institutionRoutes,
  ...purchaseRoutes,
  ...projectRoutes,
];

export default protectedRoutes;
