import React, { useState } from "react";

import {
  Group,
  Button,
  // LoadingOverlay,
  // Loader,
  Stack,
  // Notification,
} from "@mantine/core";
import tw from "twin.macro";
// import { AiFillCheckCircle } from "react-icons/ai";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import {
  // useZoomAppAuthorized,
  validateZoomAccess,
  addUserMetaDict,
} from "utils/helpers";
import MeetingForm from "./MeetingForm";
import axiosService from "utils/axios";

export const authorizeZoomApp = () => {
  let connectZoomURL = `https://zoom.us/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_ZOOM_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_ZOOM_REDIRECT_URL}`;
  window.open(connectZoomURL, "_blank");
};

export const meetingSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  info: Yup.string().required("Required"),
  mode: Yup.string().required("Required"),
  start_time: Yup.date().required("Required"),
  timezone: Yup.string().when("mode", {
    is: "online",
    then: Yup.string().required("Must enter email address"),
  }),
  hours: Yup.number().required("Required"),
  minutes: Yup.number().required("Required"),
});

const AddMeeting = ({ projectSlug, mutate, closeModal }) => {
  const [meetingMode, setMeetingMode] = useState(null);
  // const [promptAuth, setPromptAuth] = useState(false);
  // const zoomAppAuthorized = useZoomAppAuthorized(false);

  // useEffect(() => {
  //   setPromptAuth(!zoomAppAuthorized);
  // }, [zoomAppAuthorized]);

  const addOnlineMeeting = () => {
    setMeetingMode("online");
    // promptAuth && authorizeZoomApp();
  };

  // const confirmAuthorization = () => {
  //   if ("zoom_data" in localStorage) {
  //     setPromptAuth(false);
  //   } else {
  //     authorizeZoomApp();
  //   }
  // };

  const performAdd = (values, { resetForm }) => {
    let url = `/projects/${projectSlug}/meetings/`;
    let data = { ...values };
    if (data.mode === "online") {
      validateZoomAccess();
      // let access_token = getValidAccessToken();
      // data["access_token"] = access_token;
    }
    data = addUserMetaDict(data, "create");

    axiosService
      .post(url, data)
      .then((_) => {
        // console.log(res);
        toast.success("Meeting Added!");
        mutate();
        resetForm();
        closeModal();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Unable to create meeting!");
      });
    // console.log(access_token);
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      info: "",
      mode: meetingMode ?? "",
      start_time: "",
      timezone: "",
      hours: 1,
      minutes: 0,
    },
    enableReinitialize: true,
    onSubmit: (values, resetForm) => {
      // console.log(values);
      performAdd(values, resetForm);
    },
    validationSchema: meetingSchema,
  });

  return (
    <div>
      {meetingMode ? (
        <div>
          {/* <LoadingOverlay
            visible={meetingMode === "online" && promptAuth}
            loader={<FormLoader confirmAuth={confirmAuthorization} />}
          /> */}
          <MeetingForm formik={formik} cancel={closeModal} />
        </div>
      ) : (
        <Stack>
          <h3 css={tw`text-xl font-semibold text-center text-primary-500`}>
            Please select the meeting mode
          </h3>
          <Group position="center">
            <Button variant="subtle" color="teal" onClick={addOnlineMeeting}>
              Online
              {/* {promptAuth && "(Connect Zoom)"} */}
            </Button>
            <Button
              variant="subtle"
              color="lime"
              onClick={() => setMeetingMode("physical")}
            >
              In-person
            </Button>
          </Group>
        </Stack>
      )}
    </div>
  );
};

// const FormLoader = ({ confirmAuth }) => (
//   <Stack sx={tw`items-center`}>
//     <Loader size="lg" color="orange" />
//     <Notification disallowClose title="Important" color="yellow">
//       <p tw="text-primary-500">
//         Please verify your Zoom connection to move ahead!
//       </p>
//       <div tw="flex flex-col items-center mt-3">
//         <p>Authorized successfully in a new window?</p>
//         <Button
//           onClick={confirmAuth}
//           rightIcon={<AiFillCheckCircle />}
//           variant="subtle"
//           color="orange"
//           tw="mt-1"
//         >
//           Click to Confirm
//         </Button>
//       </div>
//     </Notification>
//   </Stack>
// );

export default AddMeeting;
