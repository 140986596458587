import React from "react";
import { Container as ContainerBase } from "components/misc/Layouts";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
// import illustration from "images/signup-illustration.svg";
// import logo from "images/logo-white.svg";
// import { Link } from "react-router-dom";

const Container = tw(
  ContainerBase
)`min-h-screen bg-primary-500 text-white font-medium flex justify-center`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const LabelInput = tw.input`w-full px-4 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mb-5 first:mt-0`;
const SelectInput = tw.select`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-lg bg-contain bg-center bg-no-repeat`}
`;
const InfoText = tw.p`mt-3 text-sm text-gray-600 text-center`;
const ErrorMsg = tw.div`text-xs text-red-500 mt-2`;
const FooterText = tw(Heading)`text-gray-400 text-xl`;
const LoaderGIF = tw.img`w-auto md:h-56 lg:h-64`;

const FormLayout = (props) => {
  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <IllustrationContainer>
            <IllustrationImage imageSrc={props.illustrationImageSrc} />
          </IllustrationContainer>
          <MainContainer>
            <LogoLink href={props.logoLinkUrl}>
              <LogoImage src={props.logo} />
            </LogoLink>
            <MainContent>{props.children}</MainContent>
          </MainContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};

export default FormLayout;

export {
  Container,
  Content,
  MainContainer,
  LogoLink,
  LogoImage,
  MainContent,
  Heading,
  FormContainer,
  Form,
  Input,
  LabelInput,
  SelectInput,
  SubmitButton,
  IllustrationContainer,
  IllustrationImage,
  ErrorMsg,
  LoaderGIF,
  FooterText,
  InfoText,
};
