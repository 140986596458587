import React from "react";

import tw from "twin.macro";
import { Link } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

import illustration from "../images/server-illustration.svg";
import { useLoggedIn } from "store/slices/auth";

const Wrapper = tw.section`animate-pulse text-gray-600`;
const Page404 = () => {
  const userLoggedIn = useLoggedIn();

  // console.log(userLoggedIn);

  return (
    <Wrapper>
      <div tw="container mx-auto flex lg:px-5 py-24 md:flex-row flex-col items-center">
        <div tw="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
          <img
            tw="object-cover object-center rounded"
            alt="hero"
            src={illustration}
          />
        </div>
        <div tw="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
          <h1 tw="sm:text-4xl text-3xl mb-4 font-medium text-gray-900 lg:text-6xl">
            404
          </h1>
          <p tw="mb-8 leading-relaxed">Page not Found!</p>
          <div tw="flex justify-center">
            <Link to={userLoggedIn ? "/app" : "/"}>
              <button tw="ml-4 flex items-center text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg hover:shadow-lg shadow-sm">
                <BiArrowBack />
                <span tw="ml-3">Back to Home</span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Page404;
