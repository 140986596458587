import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
// import { AccountResponse } from "../../types";

const initialState = { token: null, refreshToken: null, account: null };
const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setAuthTokens(state, action) {
      state.refreshToken = action.payload.refreshToken;
      state.token = action.payload.token;
    },
    setAccount(state, action) {
      state.account = action.payload;
    },
    logout(state) {
      state.account = null;
      state.refreshToken = null;
      state.token = null;
    },
  },
});

export default authSlice;

// Helper functions for protecting user routes
export const useLoggedIn = () => {
  const account = useSelector((state) => state.auth.account);
  return account !== null;
};

export const useAuthenticated = () => {
  const account = useSelector((state) => state.auth.account);
  return account && account.is_active && !account.is_suspended;
  // return account.is_active && !account.is_suspended;
};
