import { createSlice } from "@reduxjs/toolkit";

const initialServiceState = {
  title: "",
  categories: [],
  locations: [],
  price_range: [0, 50],
};

const searchSlice = createSlice({
  name: "search",
  initialState: initialServiceState,
  reducers: {
    setServiceSearch(state, action) {
      state.title = action.payload.title;
      state.categories = action.payload.categories;
      state.locations = action.payload.locations;
      state.price_range = action.payload.price_range;
    },

    resetServiceSearch(state) {
      state.title = "";
      state.categories = [];
      state.locations = [];
      state.price_range = [0, 50];
    },
  },
});

export default searchSlice;

export { initialServiceState };
