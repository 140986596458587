import { lazy } from "react";

// use lazy for better code splitting, a.k.a. load faster
const About = lazy(() => import("../pages/public/About"));
const Contact = lazy(() => import("../pages/public/Contact"));
const OurServices = lazy(() => import("pages/public/OurServices"));
const OurPackages = lazy(() => import("pages/public/OurPackages"));
const ServicePreview = lazy(() => import("../pages/public/ServicePreview"));
const PackagePreview = lazy(() => import("../pages/public/PackagePreview"));
const ServiceSearchResults = lazy(() =>
  import("pages/public/ServiceSearchResult")
);
const PublicBlogs = lazy(() => import("pages/blogs/PublicBlogs"));
const ReadBlog = lazy(() => import("pages/blogs/ReadBlog"));
const Checkout = lazy(() => import("pages/purchases/Checkout"));
const VerifyPurchase = lazy(() => import("pages/purchases/VerifyPayment"));

const publicRoutes = [
  {
    path: "/about", // the url
    component: About, // view rendered
    title: "About Us",
  },
  {
    path: "/contact", // the url
    component: Contact, // view rendered
    title: "Contact Us",
  },
  {
    path: "/our-services", // the url
    component: OurServices, // view rendered
    title: "Our Services",
  },
  {
    path: "/service-packages", // the url
    component: OurPackages, // view rendered
    title: "Service Packages",
  },
  {
    path: "/services/:slug/preview", // the url
    component: ServicePreview, // view rendered
    title: "Preview Service",
  },
  {
    path: "/service-packages/:slug/preview", // the url
    component: PackagePreview, // view rendered
    title: "Preview Service Package",
  },
  {
    path: "/search-service/", // the url
    component: ServiceSearchResults, // view rendered
    title: "Search Services",
  },
  {
    path: "/blog-list", // the url
    component: PublicBlogs, // view rendered
    title: "All Blogs",
    exact: true,
  },
  {
    path: "/blog/:slug/read", // the url
    component: ReadBlog, // view rendered
    title: "Read Blog",
    exact: true,
  },
  {
    path: "/checkout", // the url
    component: Checkout, // view rendered
    title: "Checkout",
    exact: true,
  },
  {
    path: "/checkout/verify", // the url
    component: VerifyPurchase, // view rendered
    title: "Verify Purchase",
    exact: true,
  },
];

export default publicRoutes;
