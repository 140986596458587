import React, { lazy, Suspense } from "react";
// import { css } from "styled-components/macro"; //eslint-disable-line

import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./index.css";
import "reactjs-popup/dist/index.css";
import "./App.css";

import Page404 from "pages/404";
import BaseRoute from "./layouts/Base";
import UBaseRoute from "./layouts/UBase";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import store, { persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

// import AuthRoutes from "routes/auth";
import authRoutes from "routes/auth";
import protectedRoutes from "routes/protected";
import publicRoutes from "routes/public";
// import MapAllowedRoutes from "routes/MapAllowedRoutes";
// import allowedRoutes from "utils/getAllowedRoutes";
import Spinner from "components/misc/Spinner";

const Index = lazy(() => import("./pages/Index"));
const ZoomClient = lazy(() =>
  import("./pages/purchases/projects/meetings/ZoomClient")
);

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <Suspense fallback={<Spinner />}>
          <Router>
            <ToastContainer position="top-right" />
            <Switch>
              <BaseRoute exact path="/" component={Index} />
              {/* <Route exact path="/">
                <Index />
              </Route> */}
              {publicRoutes.map((route, index) => {
                return (
                  <BaseRoute
                    key={index}
                    exact
                    path={route.path}
                    component={route.component}
                    docTitle={route.title}
                  />
                );
              })}

              {authRoutes.map((route, index) => {
                return (
                  <Route exact key={index} path={route.path}>
                    <route.component />
                  </Route>
                );
              })}

              {/* <MapAllowedRoutes /> */}

              {protectedRoutes.map((route, index) => {
                return (
                  <UBaseRoute
                    key={index}
                    exact={route.exact ?? false}
                    path={route.path}
                    component={route.component}
                    docTitle={route.title}
                    permissions={route?.permission}
                    authGuard={route?.authGuard}
                  />
                );
              })}

              <Route
                exact
                path="/projects/:projectSlug/meeting-:meetingID/zoom-client"
              >
                <ZoomClient />
              </Route>

              {/* <UBaseRoute exact path="/app" component={Home} />
              <UBaseRoute exact path="/profile" component={Profile} /> */}
              <Route>
                <Page404 />
              </Route>
            </Switch>
          </Router>
        </Suspense>
      </PersistGate>
    </Provider>
  );
}
