// import React from "react";
import tw from "twin.macro";

const InputWrap = tw.div`w-full my-3`;
const InputLabel = tw.label`text-white font-bold`;
const Input = tw.input`w-full px-5 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-black text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-2 first:mt-0`;
const ButtonIcon = tw.button`flex items-center justify-center`;
const SelectInput = tw.select`w-full px-5 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-black text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-2 first:mt-0`;
const TextArea = tw.textarea`w-full px-5 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-black text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-2 first:mt-0`;

export { Input, ButtonIcon, SelectInput, InputWrap, InputLabel, TextArea };
