import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { Menu, Button, Transition } from "@mantine/core";
import tw from "twin.macro";

import { useToggle } from "../provider/context";
import { extraItems } from "./data";

const Wrapper = tw.div`flex flex-col items-center justify-center p-2`;

const MenuItem = ({ item }) => {
  const currentUserRoles = useSelector((state) => state.auth.account.role);
  const { open } = useToggle();

  return (
    <Menu
      tw="mb-4"
      withArrow
      position="right"
      trigger="hover"
      control={
        <Button variant={open && "subtle"} radius="xl" color="yellow">
          <Transition
            mounted={open}
            transition="slide-left"
            duration={open ? 700 : 400}
            timingFunction="ease"
          >
            {(styles) => (
              <span style={styles} tw="mr-2">
                {item.label}
              </span>
            )}
          </Transition>
          <div css={!open && tw`bg-gray-700 p-2 rounded-full`}>{item.icon}</div>
        </Button>
      }
    >
      <Menu.Label>{item.label}</Menu.Label>
      {item.links?.map((link, lIndex) => {
        const hasAccess = link?.roles
          ? currentUserRoles.some((element) => {
              return link.roles.includes(element);
            })
          : true;

        return (
          hasAccess && (
            <Link key={lIndex} to={link.url}>
              <Menu.Item icon={link.icon} tw="text-base">
                {link.title}
              </Menu.Item>
            </Link>
          )
        );
      })}
    </Menu>
  );
};

const Extra = () => {
  const currentUserRoles = useSelector((state) => state.auth.account.role);

  return (
    <Wrapper>
      {extraItems &&
        extraItems.map((item, index) => {
          const hasAccess = item?.roles
            ? currentUserRoles.some((element) => {
                return item.roles.includes(element);
              })
            : true;

          return hasAccess && <MenuItem item={item} key={index} />;
        })}
    </Wrapper>
  );
};

export default Extra;
