import React from "react";
import { useDispatch, useSelector } from "react-redux";
import authSlice from "../../../store/slices/auth";
import { useHistory } from "react-router-dom";

import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { BiUserCircle, BiLogOut, BiLogOutCircle } from "react-icons/bi";
import { FiUser } from "react-icons/fi";
import { AiOutlineWarning } from "react-icons/ai";
import tw from "twin.macro";
import userAvatar from "images/user_avatar_white.svg";

const style = {
  active: tw`flex rounded-md items-center w-full px-2 py-2 text-[1em] bg-gray-800 text-white`,
  inactive: tw`flex rounded-md items-center w-full px-2 py-2 text-gray-700 text-[1em]`,
};

const linkItems = [
  {
    activeIcon: <BiUserCircle tw="w-5 h-5 mr-2" />,
    inactiveIcon: <FiUser tw="w-5 h-5 mr-2" />,
    url: "/app/my-account",
    label: "Account",
  },
];

export default function Dropdown() {
  const profileImg = useSelector((state) => state.auth.account?.profile_image);
  const enrv = useSelector((state) => state.auth.account.enrv);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    dispatch(authSlice.actions.logout());
    history.push("/login");
  };

  return (
    <Menu as="div">
      <Menu.Button tw="inline-flex justify-center w-full px-4 py-2 font-medium text-white rounded-md">
        <img
          alt="User Avatar"
          src={profileImg ?? userAvatar}
          tw="h-6 sm:h-10 lg:h-10 mx-auto object-cover rounded-full w-32 shadow-lg"
        />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items tw="absolute right-0 w-56 mt-2 origin-top-right bg-gray-300 divide-y divide-gray-100 rounded-md hover:shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none py-2">
          {enrv ? (
            <div tw="py-3 font-bold text-center text-red-500 hover:text-red-600 w-full flex items-center justify-center">
              <AiOutlineWarning tw="mr-2" />
              <Link to="/app/my-account/verify-email-change/">
                Email Not Verified
              </Link>
            </div>
          ) : (
            ""
          )}
          <div tw="px-1 py-1 ">
            {linkItems.map((link, index) => {
              return (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <Link
                      to={link.url}
                      css={`
                        ${active ? style.active : style.inactive}
                      `}
                    >
                      {active ? link.activeIcon : link.inactiveIcon}
                      {link.label}
                    </Link>
                  )}
                </Menu.Item>
              );
            })}
          </div>
          <div tw="px-1 py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={handleLogout}
                  css={`
                    ${active ? style.active : style.inactive}
                  `}
                >
                  {active ? (
                    <BiLogOutCircle tw="w-5 h-5 mr-2" aria-hidden="true" />
                  ) : (
                    <BiLogOut tw="w-5 h-5 mr-2" aria-hidden="true" />
                  )}
                  Logout
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
