import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";

const loader = css`
   {
    border-top-color: #faa31b;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
  }

  @-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Wrapper = tw.div`fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 bg-gray-200 opacity-75 flex flex-col items-center justify-center`;

const Spinner = () => {
  return (
    <Wrapper>
      <div
        css={loader}
        tw=" ease-linear rounded-full border-4 border-t-4 border-gray-900 h-12 w-12 mb-4"
      ></div>
      <h2 tw="text-center text-gray-400 text-xl font-semibold">Loading ...</h2>
    </Wrapper>
  );
};

export default Spinner;
