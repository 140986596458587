import { createSyntheticFile } from "@dropzone-ui/react";
import { toast } from "react-toastify";

import axiosService from "./axios";
import { authorizeZoomApp } from "pages/purchases/projects/meetings/AddMeeting";

// const preparePreview = ()

const prepareAttachmentsPreview = (attachments) => {
  attachments.forEach((item) => {
    item.file_item.file = createSyntheticFile("", 0, item.file_item.file_type);
    // fetch(item.file_item.file_url)
    //   .then((res) => res.blob()) // Gets the response and returns it as a blob
    //   .then((blob) => {
    //     // console.log(blob);
    //     item.file_item.file = createSyntheticFile(
    //       "",
    //       blob.size,
    //       item.file_item.file_type
    //     );
    //   })
    //   .catch((err) => console.log(err));
  });

  return attachments;
};

const toggleActiveStatus = (url, is_active, mutate) => {
  let data = new FormData();
  data.append("is_active", !is_active);

  axiosService
    .patch(url, data)
    .then((_) => {
      toast.success("Status Updated!");
      mutate();
    })
    .catch((err) => {
      console.log(err);
      toast.error("Unable to update!");
    });
};

const dictToFormData = (dict_data) => {
  let formdata = new FormData();

  for (var key in dict_data) {
    formdata.append(key, dict_data[key]);
  }

  return formdata;
};

const viewFormData = (formdata) => {
  for (var pair of formdata.entries()) {
    console.log(pair[0] + ", " + pair[1]);
  }
};

const addUserMeta = (formdata, type = "update") => {
  const rootState = JSON.parse(localStorage.getItem("persist:root"));
  const authState = JSON.parse(rootState.auth);
  // console.log(authState);
  const currentUserID = authState.account.id ?? null;

  if (type === "create") {
    formdata.append("created_by", currentUserID);
  }
  formdata.append("last_modified_by", currentUserID);
  return formdata;
};

const addUserMetaDict = (dict, type = "update") => {
  const rootState = JSON.parse(localStorage.getItem("persist:root"));
  const authState = JSON.parse(rootState.auth);
  // console.log(authState);
  const currentUserID = authState.account.id ?? null;

  if (type === "create") {
    dict.created_by = currentUserID;
  }
  dict.last_modified_by = currentUserID;
  return dict;
};

const redirectToLogin = (current_url) => {
  sessionStorage.setItem("url_to_load", current_url);
  window.location.href = "/login";
};

const checkZoomConnection = () => {
  axiosService
    .get("projects/get-zoom-connection/")
    .then((res) => {
      // console.log(res);
      sessionStorage.setItem("connection_exists", res.data.connection_exists);
      // connectionExists = res.data.connection_exists;
      // return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const checkZoomAppAuthorized = () => {
  // let appAuthorized = "zoom_data" in localStorage;
  // if (appAuthorized) {
  //   let zoomData = localStorage.getItem("zoom_data");
  //   if (zoomData === null || zoomData === undefined) {
  //     appAuthorized = false;
  //   }
  // }
  // return appAuthorized;
  // var connectionExists = false;
  // if ("connection_exists" in sessionStorage) {
  //   connectionExists = sessionStorage.getItem("connection_exists") === "true";
  //   sessionStorage.removeItem("connection_exists");
  // }
  // console.log("Connection Exists", connectionExists);
  // return connectionExists;
  checkZoomConnection();
  let authorized = sessionStorage.getItem("connection_exists") === "true";
  return authorized;
};

const useZoomAppAuthorized = () => {
  let authorized = checkZoomAppAuthorized();
  console.log(authorized);
  return authorized;
};

const validateZoomAccess = () => {
  // let zoomData = JSON.parse(localStorage.getItem("zoom_data"));
  // // console.log(zoomData);
  // let data = {
  //   access: zoomData.access_token,
  //   refresh: zoomData.refresh_token,
  //   expires_in: zoomData.expires_in,
  // };
  // console.log(data);

  axiosService
    .post("/projects/validate-zoom-access/", {})
    // .then((_) => {
    //   console.log("Zoom Acccess Validated!");
    //   // let tokens = res.data;
    //   // if (tokens.hasOwnProperty("refresh")) {
    //   //   // update the local storage as well
    //   //   let updatedZoomData = {
    //   //     user_email: zoomData.user_email,
    //   //     account_id: zoomData.account_id,
    //   //     access_token: tokens.access,
    //   //     refresh_token: tokens.refresh,
    //   //     expires_in: tokens.expires_in,
    //   //   };
    //   //   localStorage.setItem("zoom_data", JSON.stringify(updatedZoomData));
    //   //   // console.log("local storage updated with new tokens 🚀");
    //   // }
    // })
    .catch((err) => {
      console.log(err);
      toast.error("Unable to validate Zoom access!");
    });
};

const getValidAccessToken = () => {
  let zoomAuthorized = checkZoomAppAuthorized();
  console.log(zoomAuthorized);

  if (zoomAuthorized) {
    validateZoomAccess();
    // let zoomData = JSON.parse(localStorage.getItem("zoom_data"));
    // return zoomData.access_token;
  } else {
    authorizeZoomApp();
    return;
  }
};

export {
  dictToFormData,
  viewFormData,
  addUserMeta,
  addUserMetaDict,
  prepareAttachmentsPreview,
  toggleActiveStatus,
  redirectToLogin,
  useZoomAppAuthorized,
  validateZoomAccess,
  getValidAccessToken,
};
