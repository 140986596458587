import React, { useState, useLayoutEffect } from "react";
import { Redirect, useParams } from "react-router-dom";

import useSWR from "swr";
import { toast } from "react-toastify";

import { fetcher } from "utils/axios";

const ProjectHOC = ({ children }) => {
  const { projectSlug } = useParams();
  // const [hasChecked, setHasChecked] = useState(false);
  const [hasAccess, setHasAccess] = useState(true);
  const { data: accessData, err: checkAccessErr } = useSWR(
    `/projects/${projectSlug}/check-access/`,
    fetcher
  );

  //   console.log(accessData);

  useLayoutEffect(() => {
    if (!accessData) return;
    if (checkAccessErr) {
      setHasAccess(false);
      toast.error("Unable to validate access!");
    }
    !accessData.has_access && setHasAccess(false);
  }, [accessData, checkAccessErr]);

  return <>{hasAccess ? children : <Redirect to="/app" />}</>;
};

export default ProjectHOC;
