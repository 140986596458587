import tw from "twin.macro";
import { Link } from "react-router-dom";

import logo from "images/logo-light.svg";

const Wrapper = tw.div`bg-gray-900 flex items-center justify-center mb-6 pb-6 pt-3 sticky top-0 z-10`;

export default function SidenavHeader() {
  return (
    <Wrapper>
      <Link to="/">
        <img tw="mt-4" src={logo} width={80} height={90} alt="Enoch Ndika" />
      </Link>
    </Wrapper>
  );
}
