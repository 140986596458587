import React, { useState } from "react";
import { Link } from "react-router-dom";

import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { GrUserWorker } from "react-icons/gr";

import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
// import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import NotAvailable from "components/misc/NotAvailable";
import { ServiceLocations } from "pages/service/ManageService";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 px-5`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-5`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;
const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`,
]);

const TextInfo = tw.div`p-3 sm:p-5`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold line-clamp-1`;

export const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
export const Rating = tw.span`ml-2 font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4 line-clamp-1`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
export const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
export const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
export const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;
const Div = tw.div``;

// const PrimaryButton = tw(
//   PrimaryButtonBase
// )`mt-auto sm:text-lg w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;

const Carousel = ({ services }) => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 2,
    infinite: false,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  /* Change this according to your needs */
  // const cards = [
  //   {
  //     imageSrc:
  //       "https://images.unsplash.com/photo-1573497491208-6b1acb260507?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80",
  //     title: "Legal Consulting",
  //     description:
  //       "Lorem ipsum dolor sit amet, consectur dolori adipiscing elit, sed do eiusmod tempor nova incididunt ut labore et dolore magna aliqua.",
  //     locationText: "KOX",
  //     pricingText: "From USD 39",
  //     rating: "4.8",
  //   },
  //   {
  //     imageSrc:
  //       "https://images.unsplash.com/photo-1521791136064-7986c2920216?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1769&q=80",
  //     title: "License Agreement",
  //     description:
  //       "Lorem ipsum dolor sit amet, consectur dolori adipiscing elit, sed do eiusmod tempor nova incididunt ut labore et dolore magna aliqua.",
  //     locationText: "KOX Partner",
  //     pricingText: "From USD 50",
  //     rating: 4.9,
  //   },
  //   {
  //     imageSrc:
  //       "https://images.unsplash.com/photo-1599420186946-7b6fb4e297f0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
  //     title: "Real Estate Solutions",
  //     description:
  //       "Lorem ipsum dolor sit amet, consectur dolori adipiscing elit, sed do eiusmod tempor nova incididunt ut labore et dolore magna aliqua.",
  //     locationText: "KOX Partner",
  //     pricingText: "USD 99/Day",
  //     rating: 4.5,
  //   },
  //   {
  //     imageSrc:
  //       "https://images.unsplash.com/photo-1516321318423-f06f85e504b3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80",
  //     title: "Contract Creation",
  //     description:
  //       "Lorem ipsum dolor sit amet, consectur dolori adipiscing elit, sed do eiusmod tempor nova incididunt ut labore et dolore magna aliqua.",
  //     locationText: "KOX",
  //     pricingText: "From USD 19/Day",
  //     rating: "5.0",
  //   },
  // ];

  return (
    <Container>
      <Content>
        {services &&
          (services.length > 0 ? (
            <>
              <HeadingWithControl>
                <Heading>Popular Services</Heading>
                <Controls>
                  <PrevButton onClick={sliderRef?.slickPrev}>
                    <ChevronLeftIcon />
                  </PrevButton>
                  <NextButton onClick={sliderRef?.slickNext}>
                    <ChevronRightIcon />
                  </NextButton>
                </Controls>
              </HeadingWithControl>
              <CardSlider ref={setSliderRef} {...sliderSettings}>
                {/* {cards.map((card, index) => (
                  <Card key={index}>
                    <CardImage imageSrc={card.imageSrc} />
                    <TextInfo>
                      <TitleReviewContainer>
                        <Title>{card.title}</Title>
                        <RatingsInfo>
                          <StarIcon />
                          <Rating>{card.rating}</Rating>
                        </RatingsInfo>
                      </TitleReviewContainer>
                      <SecondaryInfoContainer>
                        <IconWithText>
                          <IconContainer tw="bg-gray-300">
                            <GrUserWorker />
                          </IconContainer>
                          <Text>{card.locationText}</Text>
                        </IconWithText>
                        <IconWithText>
                          <IconContainer>
                            <PriceIcon />
                          </IconContainer>
                          <Text>{card.pricingText}</Text>
                        </IconWithText>
                      </SecondaryInfoContainer>
                      <Description>{card.description}</Description>
                    </TextInfo>
                    <PrimaryButton>Learn More</PrimaryButton>
                  </Card>
                ))} */}
                {services.map((service, index) => (
                  <Card key={index}>
                    <CardImage imageSrc={service.thumbnail} />
                    <TextInfo>
                      <TitleReviewContainer>
                        <Link
                          to={service.preview_url}
                          tw="hover:text-yellow-500"
                        >
                          <Title>{service.title}</Title>
                        </Link>
                      </TitleReviewContainer>
                      <SecondaryInfoContainer>
                        <IconWithText>
                          <IconContainer tw="bg-gray-300">
                            <GrUserWorker />
                          </IconContainer>
                          <Text>{service.provider}</Text>
                        </IconWithText>
                        <IconWithText>
                          <IconContainer>
                            <PriceIcon />
                          </IconContainer>
                          <Text>{service.price_holder}</Text>
                        </IconWithText>
                      </SecondaryInfoContainer>
                      <Description>{service.description}</Description>
                      <Div
                        css={[
                          tw`flex mt-3`,
                          service.locations.length > 0
                            ? tw`justify-between items-center`
                            : tw`justify-end`,
                        ]}
                      >
                        {service.locations.length > 0 && (
                          <ServiceLocations locations={service.locations} />
                        )}

                        <RatingsInfo>
                          <StarIcon />
                          <Rating>{service.custom_ratings}</Rating>
                        </RatingsInfo>
                      </Div>
                    </TextInfo>
                    {/* <PrimaryButton>Learn More</PrimaryButton> */}
                  </Card>
                ))}
              </CardSlider>
            </>
          ) : (
            <NotAvailable
              isDark
              label="We're updating this! Please stay tuned."
              showLoader
            />
          ))}
      </Content>
    </Container>
  );
};

export default Carousel;
