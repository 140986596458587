import React from "react";
import { Route } from "react-router-dom";
import tw from "twin.macro";

import Header from "../components/headers/light";
import Footer from "../components/footers/FiveColumnWithBackground.js";
import useDocTitle from "utils/useDocTitle";
// import AnimationRevealPage from "helpers/AnimationRevealPage";

const StyledDiv = tw.div`font-display text-secondary-500 p-8 h-screen overflow-auto overflow-x-hidden`;

const BaseLayout = ({ children }) => {
  return (
    <StyledDiv className="App">
      <Header roundedHeaderButton={true} />
      <>{children}</>
      <Footer />
    </StyledDiv>
  );
};

// export { BaseLayout };

const BaseRoute = ({ component: Component, docTitle, ...rest }) => {
  let title = "KOX - Keep on";
  if (docTitle) {
    title = `KOX | ${docTitle}`;
  }
  useDocTitle(title);

  return (
    <Route
      {...rest}
      render={(props) => (
        <BaseLayout>
          <Component {...props} />
        </BaseLayout>
      )}
    />
  );
};

export default BaseRoute;
