import React from "react";

import {
  Group,
  Button,
  NumberInput,
  Select,
  Textarea,
  Badge,
} from "@mantine/core";
import tw from "twin.macro";

import { InputWrap, InputLabel, Input } from "components/misc/Inputs";
import { TwoColumn, Column } from "pages/profiles/ApplicationForm";
import { selectStyle } from "components/misc/Mantine";
import { ErrorMsg } from "components/misc/FormLayout";

const Label = tw(InputLabel)`text-gray-800 font-medium`;
const Form = tw.form``;

const MeetingForm = ({ formik, cancel, isUpdate = false }) => {
  const toggleMeetingMode = () => {
    if (isUpdate) return;
    formik.values.mode === "online" && formik.setFieldValue("mode", "physical");
    formik.values.mode === "physical" && formik.setFieldValue("mode", "online");
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Group position="right" tw="items-center">
        <span>Mode:</span>
        <Button
          compact
          onClick={toggleMeetingMode}
          type="button"
          variant="white"
          tw="p-0"
        >
          <Badge
            radius="sm"
            variant={formik.values.mode === "online" ? "dot" : "outline"}
            color={formik.values.mode === "online" ? "green" : "lime"}
            sx={
              !isUpdate && [
                tw`cursor-pointer hover:text-white`,
                formik.values.mode === "online"
                  ? tw`hover:bg-gray-500`
                  : tw`hover:bg-green-500`,
              ]
            }
          >
            {formik.values.mode === "physical"
              ? "In-person"
              : formik.values.mode}
          </Badge>
        </Button>
      </Group>
      <InputWrap>
        <Label>Meeting Title</Label>
        <Input
          name="title"
          type="text"
          value={formik.values.title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.title && formik.errors.title && (
          <ErrorMsg>{formik.errors.title} </ErrorMsg>
        )}
      </InputWrap>
      <InputWrap>
        <Label>Meeting Info</Label>
        <Textarea
          autosize
          pt="xs"
          styles={selectStyle}
          name="info"
          minRows={4}
          value={formik.values.info}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        ></Textarea>
        {formik.touched.info && formik.errors.info && (
          <ErrorMsg>{formik.errors.info} </ErrorMsg>
        )}
      </InputWrap>

      <TwoColumn>
        <Column tw="w-full sm:mr-4">
          <InputWrap>
            <Label>Start Time</Label>
            <Input
              name="start_time"
              type="datetime-local"
              value={formik.values.start_time}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.start_time && formik.errors.start_time && (
              <ErrorMsg>{formik.errors.start_time} </ErrorMsg>
            )}
          </InputWrap>
        </Column>
        {formik.values.mode === "online" && (
          <Column tw="w-full sm:ml-4">
            <InputWrap>
              <Label>Timezone</Label>
              <Select
                pt="xs"
                size="lg"
                styles={selectStyle}
                name="timezone"
                placeholder="Please select one..."
                data={[
                  { value: "Asia/Kathmandu", label: "Kathmandu, Nepal" },
                  { value: "Asia/Calcutta", label: "Calcutta, India" },
                ]}
                value={formik.values.timezone}
                onChange={(value) => formik.setFieldValue("timezone", value)}
                onBlur={formik.handleBlur}
              />
              {formik.touched.timezone && formik.errors.timezone && (
                <ErrorMsg>{formik.errors.timezone} </ErrorMsg>
              )}
            </InputWrap>
          </Column>
        )}
      </TwoColumn>

      <Group mb="xs" tw="flex-col sm:flex-row" position="center">
        <Label>Duration</Label>
        <TwoColumn tw="flex-1 justify-center">
          <Column tw="sm:mr-3">
            <NumberInput
              // defaultValue={18}
              name="hours"
              styles={selectStyle}
              placeholder="hrs"
              label="Hours"
              value={formik.values.hours}
              onChange={(value) => formik.setFieldValue("hours", value)}
              onBlur={formik.handleBlur}
            />
            {formik.touched.hours && formik.errors.hours && (
              <ErrorMsg>{formik.errors.hours} </ErrorMsg>
            )}
          </Column>

          <Column tw="sm:ml-3">
            <NumberInput
              name="minutes"
              styles={selectStyle}
              placeholder="mins"
              label="Minutes"
              value={formik.values.minutes}
              onChange={(value) => formik.setFieldValue("minutes", value)}
              onBlur={formik.handleBlur}
            />
            {formik.touched.minutes && formik.errors.minutes && (
              <ErrorMsg>{formik.errors.minutes} </ErrorMsg>
            )}
          </Column>
        </TwoColumn>
      </Group>

      <Group tw="justify-between" mt="md">
        <Button type="submit" color={isUpdate ? "yellow" : "green"}>
          {isUpdate ? "Save Changes" : "Submit"}
        </Button>
        <Button onClick={cancel} type="button" color="red" variant="subtle">
          Cancel
        </Button>
      </Group>
    </Form>
  );
};

export default MeetingForm;
