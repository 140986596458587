import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";

import {
  ActionIcon,
  Drawer,
  Indicator,
  Button,
  Notification,
  Transition,
  ScrollArea,
} from "@mantine/core";
import {
  AiFillCalculator,
  AiOutlineShoppingCart,
  AiOutlineMinusSquare,
  AiFillRightCircle,
  AiFillWarning,
} from "react-icons/ai";
import { FcServices, FcPackage } from "react-icons/fc";
import tw from "twin.macro";
import { toast } from "react-toastify";

import logo from "../../images/logo-white.svg";
import NotAvailable from "components/misc/NotAvailable";
import axiosService from "utils/axios";
import cartSlice, { useCartItemsCount } from "store/slices/cart";
import { ViewPlans } from "pages/service/packages/ListPackage";

const Cart = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const cartItems = useSelector((state) => state.cart);
  const cartItemsCount = useCartItemsCount();
  const [showCart, setShowCart] = useState(false);
  const [processedItems, setProcessedItems] = useState([]);
  const [activePackageID, setActivePackageID] = useState(null);

  // processedItems && console.log(processedItems);

  useEffect(() => {
    axiosService
      .post("/purchases/cart-items/", cartItems)
      .then((res) => {
        // console.log(res);
        setProcessedItems(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Unable to load cart!");
      });
  }, [cartItems]);

  const removeServiceItem = (planID) => {
    dispatch(
      cartSlice.actions.setCartItems({
        services: cartItems.services.filter((item) => item.plan_id !== planID),
      })
    );
    toast.info("Service Item Removed!");
  };

  const toggleActivePackageID = (packageID) => {
    if (activePackageID === packageID) {
      setActivePackageID(null);
    } else {
      setActivePackageID(packageID);
    }
  };

  const removePackageItem = (packageID) => {
    dispatch(
      cartSlice.actions.setCartPackages({
        packages: cartItems.packages.filter((id) => id !== packageID),
      })
    );
    toast.info("Service Package Removed!");
  };

  return (
    <>
      <Indicator
        ml="lg"
        mr="sm"
        withBorder
        color="yellow"
        inline
        label={cartItemsCount}
        size={24}
      >
        <ActionIcon
          onClick={() => setShowCart(true)}
          variant={cartItemsCount > 0 ? "filled" : "light"}
          color="yellow"
          radius="md"
          size="lg"
        >
          <AiOutlineShoppingCart fontSize={25} />
        </ActionIcon>
      </Indicator>

      <Drawer
        opened={showCart}
        onClose={() => setShowCart(false)}
        title={<h2 css={tw`text-xl font-bold text-primary-500`}>Cart</h2>}
        padding="xl"
        size="xl"
        position="right"
        styles={{
          drawer: { backgroundColor: "#FEEBCF" },
        }}
      >
        {/* Drawer content */}
        <div tw="flex items-center justify-center w-full sm:hidden mb-3">
          <img height={100} width={100} src={logo} alt="KOX" />
        </div>

        {processedItems?.total_prices &&
          Object.keys(processedItems.total_prices).length > 1 && (
            <Notification
              disallowClose
              icon={<AiFillWarning fontSize={25} />}
              color="yellow"
              tw="font-bold my-3 bg-transparent"
            >
              Error:
              <span tw="text-secondary-500 italic ml-2">
                Please select items for a single location
              </span>
            </Notification>
          )}

        <div tw="w-full mb-5">
          <h2 tw="flex items-center text-gray-700">
            <AiFillCalculator fontSize={25} />

            <p tw="text-lg font-bold ml-2">Total ({cartItemsCount})</p>
          </h2>
          <div tw="flex items-center justify-end">
            {processedItems?.total_prices &&
              Object.keys(processedItems.total_prices).map((key, index) => (
                <p key={index} tw="text-base font-semibold mr-3">
                  {key}{" "}
                  <span tw="font-bold text-lg">
                    {processedItems.total_prices[key]}
                  </span>
                </p>
              ))}
          </div>
        </div>

        {cartItemsCount > 0 ? (
          <ScrollArea
            // css={[
            //   tw`h-7/12 sm:h-9/12`,
            //   processedItems?.total_prices &&
            //     Object.keys(processedItems.total_prices).length > 1 &&
            //     tw`h-1/2`,
            // ]}
            tw="h-2/3"
            type="auto"
            offsetScrollbars
            scrollbarSize={6}
          >
            {processedItems?.services?.length > 0 && (
              <div tw="block p-5 sm:px-6 border-4 border-gray-200 border-double bg-yellow-50 rounded-b-xl shadow-[0_4px_0_0_rgba(0,0,0,1)] w-full">
                <div tw="flex items-center justify-between">
                  <h2 tw="flex items-center text-gray-700">
                    <FcServices fontSize={25} />

                    <p tw="text-lg font-semibold ml-2">
                      Services ({cartItems.services.length})
                    </p>
                  </h2>
                </div>
                {/* <div tw="flex items-center justify-end">
                  {processedItems?.service_prices &&
                    Object.keys(processedItems.service_prices).map(
                      (key, index) => (
                        <p key={index} tw="text-base font-semibold mr-3">
                          {key}{" "}
                          <span tw="font-bold text-lg">
                            {processedItems.service_prices[key]}
                          </span>
                        </p>
                      )
                    )}
                </div> */}

                <div tw="w-full pt-8 pb-12">
                  {processedItems?.services?.length > 0 &&
                    processedItems?.services.map((item, index) => (
                      <div
                        key={index}
                        css={[
                          tw`flex items-center justify-between`,
                          index + 1 < processedItems.services.length &&
                            tw`mb-2 pb-2 border-b-2 border-dotted border-gray-lightest`,
                        ]}
                      >
                        <div tw="flex items-start">
                          <img
                            tw="object-cover w-20 sm:w-24 h-20 border-2 border-black rounded-lg"
                            src={item.service.thumbnail}
                            alt={item.service.title}
                          />

                          <div tw="ml-4">
                            <Link
                              to={item.service.preview_url}
                              tw="text-lg font-semibold hover:text-yellow-500"
                            >
                              {item.service.title}
                            </Link>

                            <dl tw="mt-1 space-y-1 text-base text-primary-500">
                              <div>
                                <dt tw="inline text-gray-600 text-sm mr-1">
                                  Plan:
                                </dt>
                                <dd tw="inline">{item.title}</dd>
                              </div>

                              <div>
                                <dt tw="inline text-gray-600 text-sm mr-1">
                                  Price:
                                </dt>
                                <dd tw="inline">
                                  {item.currency} {item.price}
                                </dd>
                              </div>
                            </dl>
                          </div>
                        </div>

                        <ActionIcon
                          onClick={() => removeServiceItem(item.id)}
                          variant="transparent"
                          color="red"
                        >
                          <AiOutlineMinusSquare fontSize={25} />
                        </ActionIcon>
                      </div>
                    ))}
                </div>
              </div>
            )}

            {processedItems?.packages?.length > 0 && (
              <div tw="block p-5 sm:px-6 border-4 border-gray-200 border-double bg-yellow-50 rounded-b-xl shadow-[0_4px_0_0_rgba(0,0,0,1)] w-full">
                <div tw="flex items-center justify-between">
                  <h2 tw="flex items-center text-gray-700">
                    <FcPackage fontSize={25} />

                    <p tw="text-lg font-semibold ml-2">
                      Service Packages ({cartItems.packages.length})
                    </p>
                  </h2>
                </div>
                {/* <div tw="flex items-center justify-end">
                  {processedItems?.service_prices &&
                    Object.keys(processedItems.service_prices).map(
                      (key, index) => (
                        <p key={index} tw="text-base font-semibold mr-3">
                          {key}{" "}
                          <span tw="font-bold text-lg">
                            {processedItems.service_prices[key]}
                          </span>
                        </p>
                      )
                    )}
                </div> */}

                <div tw="w-full pt-8 pb-12">
                  {processedItems.packages.map((packageItem, index) => (
                    <div key={index}>
                      <div
                        css={[
                          tw`flex items-center justify-between`,
                          index + 1 < processedItems.packages.length &&
                            activePackageID !== packageItem.id &&
                            tw`mb-2 pb-2 border-b-2 border-dotted border-gray-lightest`,
                        ]}
                      >
                        <div tw="flex items-start">
                          <img
                            tw="object-cover w-20 sm:w-24 h-20 border-2 border-secondary-500 rounded-lg"
                            src={packageItem.thumbnail}
                            alt={packageItem.name}
                          />

                          <div tw="ml-4">
                            <p
                              // to={item.service.preview_url}
                              tw="text-lg font-semibold hover:text-yellow-500"
                            >
                              {packageItem.name}
                            </p>

                            <dl tw="mt-1 space-y-1 text-base text-primary-500">
                              <div>
                                <dt tw="inline text-gray-600 text-sm mr-1">
                                  Price:
                                </dt>
                                <dd tw="inline">
                                  {packageItem.currency} {packageItem.price}
                                </dd>
                              </div>

                              <div>
                                <Button
                                  onClick={() =>
                                    toggleActivePackageID(packageItem.id)
                                  }
                                  compact
                                  variant="subtle"
                                  color={
                                    activePackageID === packageItem.id
                                      ? "red"
                                      : "orange"
                                  }
                                >
                                  {activePackageID === packageItem.id
                                    ? "Hide"
                                    : "Show"}{" "}
                                  Plans ({packageItem.plans.length})
                                </Button>
                              </div>
                            </dl>
                          </div>
                        </div>

                        <ActionIcon
                          onClick={() => removePackageItem(packageItem.id)}
                          variant="transparent"
                          color="red"
                        >
                          <AiOutlineMinusSquare fontSize={25} />
                        </ActionIcon>
                      </div>

                      <Transition
                        mounted={activePackageID === packageItem.id}
                        transition="fade"
                        duration={400}
                        timingFunction="ease"
                      >
                        {(styles) => (
                          <div
                            style={styles}
                            tw="border-gray-lightest border-b-2 border-dotted mb-3"
                          >
                            <ViewPlans isCartView packageItem={packageItem} />
                          </div>
                        )}
                      </Transition>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </ScrollArea>
        ) : (
          <NotAvailable isDark label="Looks a little empty here!!" />
        )}

        <div tw="w-full space-y-4 text-center mt-5">
          <Button
            onClick={() => history.push("/checkout")}
            disabled={cartItemsCount === 0}
            color="yellow"
            size="md"
            tw="w-full rounded-full hover:ring-1 hover:ring-yellow-500"
            rightIcon={<AiFillRightCircle />}
          >
            Check out
          </Button>

          <span
            onClick={() => setShowCart(false)}
            tw="inline-block text-base tracking-wide underline text-underline-offset[initial] hover:text-lg cursor-pointer"
          >
            Continue Purchase
          </span>
        </div>
      </Drawer>
    </>
  );
};

export default Cart;
