import { lazy } from "react";

import ProjectHOC from "routes/authHOCs/ProjectHOC";

const ProjectDetail = lazy(() =>
  import("pages/purchases/projects/ProjectDetail")
);
const ConnectZoom = lazy(() =>
  import("pages/purchases/projects/meetings/ConnectZoom")
);
const ManageTeamInvitations = lazy(() => 
  import("pages/purchases/projects/teams/TeamInvitation")
);
// const ZoomClient = lazy(() =>
//   import("pages/purchases/projects/meetings/ZoomClient")
// );

const ProjectTasks = lazy(() =>
  import("pages/purchases/projects/tasks/ProjectTasks")
);

const ManageProjectReports = lazy(() =>
  import("pages/purchases/projects/reports/ManageProjectReports")
);

const ProjectReports = lazy(() =>
  import("pages/purchases/projects/reports/ClientReports")
);

const RoomMessages = lazy(() =>
  import("pages/purchases/projects/chat/RoomMessages")
);

const projectRoutes = [
  {
    path: "/projects/reports", // the url
    component: ManageProjectReports, // view rendered
    title: "Project Reports",
    exact: true,
    permission: ["Admin"],
  },
  {
    path: "/projects/:projectSlug", // the url
    component: ProjectDetail, // view rendered
    title: "Project Details",
    exact: true,
    authGuard: ProjectHOC,
  },
  {
    path: "/projects/:projectSlug/reports", // the url
    component: ProjectReports, // view rendered
    title: "Project Reports",
    exact: true,
    authGuard: ProjectHOC,
  },
  {
    path: "/projects/:projectSlug/tasks", // the url
    component: ProjectTasks, // view rendered
    title: "Project Tasks",
    exact: true,
    authGuard: ProjectHOC,
  },
  {
    path: "/projects/:projectSlug/:roomId/messages", // the url
    component: RoomMessages, // view rendered
    title: "Project Messages",
    exact: true,
  },
  {
    path: "/connect-with-zoom", // the url
    component: ConnectZoom, // view rendered
    title: "Connect with Zoom",
    exact: true,
  },
  {
    path: "/manage-invitations", // the url
    component: ManageTeamInvitations, // view rendered
    title: "Manage Project Invitations",
    exact: true,
  },
  // {
  //   path: "/projects/:projectSlug/meeting-:meetingID/zoom-client", // the url
  //   component: ZoomClient, // view rendered
  //   title: "Zoom Client",
  //   exact: true,
  //   authGuard: ProjectHOC,
  // },
];

export default projectRoutes;
