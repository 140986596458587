import { lazy } from "react";

// use lazy for better code splitting, a.k.a. load faster
const ManageCountries = lazy(() => import("pages/locations/ManageCountries"));
const ManageCurrencies = lazy(() => import("pages/locations/ManageCurrencies"));

const locationRoutes = [
  {
    path: "/manage-countries", // the url
    component: ManageCountries, // view rendered
    title: "Manage Countries",
    exact: true,
    permission: ["Admin"],
  },
  {
    path: "/manage-currencies", // the url
    component: ManageCurrencies, // view rendered
    title: "Manage Currencies",
    exact: true,
    permission: ["Admin"],
  },
];

export default locationRoutes;
