import tw from "twin.macro";
import { Button } from "@mantine/core";
import { IoIosSave } from "react-icons/io";
import { AiOutlinePlus } from "react-icons/ai";

export const PrimaryButton = tw.button`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`;

const SaveChangesButton = (props) => (
  <Button {...props} rightIcon={<IoIosSave />} color="yellow">
    Save Changes
  </Button>
);

const AddButton = (props) => (
  <button
    onClick={props.onClick}
    tw="flex items-center bg-indigo-500 hover:bg-indigo-400 text-white font-bold py-2 px-4 border-b-4 border-indigo-700 hover:border-indigo-500 rounded mt-5 lg:mr-20 md:mr-5"
  >
    <AiOutlinePlus />
    <span tw="ml-2">{props.text}</span>
  </button>
);

export { SaveChangesButton, AddButton };
