import tw from "twin.macro";

import SidenavItems from "./items";
import SidenavHeader from "./header";
import ExtraItems from "./extra";
import css from "./style.module.css";
import { useToggle } from "../provider/context";

import { Divider } from "@mantine/core";

const style = {
  mobilePosition: {
    left: tw`left-0 `,
    right: tw`right-0 lg:left-0`,
  },
  container: tw`pb-32 lg:pb-12 justify-items-center`,
  close: tw`duration-700 ease-out hidden transition-all lg:w-24`,
  open: tw`absolute duration-500 ease-in transition-all w-8/12 z-40 sm:w-5/12 md:w-64`,
  default: tw`h-screen overflow-y-auto overflow-x-hidden text-white top-0 lg:absolute bg-gray-900 lg:block lg:z-40`,
};

export default function SideNavigation({ mobilePosition }) {
  const { open, ref } = useToggle();
  return (
    <aside
      ref={ref}
      css={`
        ${style.default} ${style.mobilePosition[mobilePosition]} 
        ${open ? style.open : style.close} ${css.scrollbar}
      `}
      className="scrollbar"
    >
      <div css={style.container}>
        <SidenavHeader />
        {/* <div tw="max-h-full overflow-y-auto overflow-x-hidden"> */}
        <SidenavItems />

        <Divider my="sm" color="dark" />
        <ExtraItems />
        {/* </div> */}
      </div>
    </aside>
  );
}
