import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { Tooltip } from "@mantine/core";
import tw from "twin.macro";

import data from "./data";
import { useToggle } from "../provider/context";

const style = {
  title: tw`mx-4 text-sm`,
  active: tw`bg-yellow-500 rounded-full p-2`,
  link: tw`flex items-center justify-start my-1 p-3 w-full hover:text-white`,
  close: tw`lg:duration-700 lg:ease-out lg:invisible lg:opacity-0 lg:transition-all`,
  open: tw`lg:duration-500 lg:ease-in lg:h-auto lg:opacity-100 lg:transition-all lg:w-auto`,
};

export default function SidenavItems() {
  const { pathname } = useLocation();
  const { open } = useToggle();
  const currentUserRole = useSelector((state) => state.auth.account.role);

  return (
    <ul tw="md:pl-3">
      <li>
        {data.map((item, index) => {
          // console.log(item.link, pathname);
          let linkItem = (
            <Link to={item.link} key={index}>
              <span css={style.link}>
                <Tooltip
                  color="yellow"
                  position="right"
                  transition="fade"
                  transitionDuration={500}
                  label={item.title}
                  withArrow
                >
                  <div
                    css={`
                      ${item.link === pathname || pathname.includes(item.link)
                        ? style.active
                        : tw`bg-gray-700 rounded-full p-2`}
                    `}
                  >
                    <span>{item.icon}</span>
                  </div>
                </Tooltip>
                <span
                  css={`
                    ${style.title} ${open ? style.open : style.close}
                  `}
                >
                  {item.title}
                </span>
              </span>
            </Link>
          );
          if (item.roles) {
            const hasAccess = currentUserRole.some((element) => {
              return item.roles.includes(element);
            });
            if (hasAccess) {
              return linkItem;
            } else {
              return null;
            }
          } else {
            return linkItem;
          }
        })}
      </li>
    </ul>
  );
}
