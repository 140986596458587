import tw from "twin.macro";

const inputStyle = {
  input: tw`w-full px-4 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 mb-5`,
  baseInput: tw`w-full px-5 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-black text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-2 first:mt-0`,
};

const selectStyle = {
  input: tw`rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400`,
  baseInput: tw`rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-black text-sm focus:outline-none focus:border-gray-400 focus:bg-white`,
};

export { inputStyle, selectStyle };
