import { lazy } from "react";

// use lazy for better code splitting, a.k.a. load faster
const Blogs = lazy(() => import("pages/blogs/index"));
// const ServiceDashboard = lazy(() => import("pages/service/dashboard/ServiceDashboard"))
// const ManageEnquiries = lazy(() => import("pages/service/enquiries/ManageEnquiries"))
// const ManageProcedure = lazy(() => import("pages/service/procedure/ManageProcedure"))
// const Plan = lazy(() => import("pages/service/plans/index"))
// const ManageDuration = lazy(() => import("pages/service/plans/ManageDuration"))

const blogRoutes = [
  {
    path: "/blog", // the url
    component: Blogs, // view rendered
    title: "Blogs",
    exact: true,
  },
];

export default blogRoutes;
