import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const initialCartState = {
  services: [],
  packages: [],
  payment_method: null,
};

const cartSlice = createSlice({
  name: "cart",
  initialState: initialCartState,
  reducers: {
    setCartItems(state, action) {
      state.services = action.payload.services;
    },
    setCartPackages(state, action) {
      state.packages = action.payload.packages;
    },
    setPaymentMethod(state, action) {
      state.payment_method = action.payload.payment_method;
    },
    clearCart(state) {
      state.services = [];
      state.packages = [];
      state.payment_method = null;
    },
  },
});

export default cartSlice;

const useCartItemsCount = () => {
  const services = useSelector((state) => state.cart.services);
  const packages = useSelector((state) => state.cart.packages);
  return services.length + packages.length;
};

export { initialCartState, useCartItemsCount };
