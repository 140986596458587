import { lazy } from "react";

// use lazy for better code splitting, a.k.a. load faster
const ApplyProfile = lazy(() => import("pages/profiles/ProfileApplication"));
const UpdateProfile = lazy(() => import("pages/profiles/UpdateApplication"));
const ManageProfile = lazy(() => import("pages/profiles/admin/ManageProfile"));

const profileRoutes = [
  {
    path: "/profile-application", // the url
    component: ApplyProfile, // view rendered
    title: "My Profiles",
    exact: true,
    permission: ["Client"],
  },
  {
    path: "/profile-application/A-:profileID/update", // the url
    component: UpdateProfile, // view rendered
    title: "Update Profile",
    exact: true,
    permission: ["Admin", "Client"],
  },
  {
    path: "/manage-profiles/:profileType", // the url
    component: ManageProfile, // view rendered
    title: "Manage Profiles",
    exact: true,
    permission: ["Admin", "Service Provider"],
  },
];

export default profileRoutes;
