import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import tw from "twin.macro";
import {
  Avatar,
  Divider,
  Button,
  Transition,
  Group,
  ActionIcon,
} from "@mantine/core";
import { GrUserWorker } from "react-icons/gr";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { BsCartPlus, BsFillCartCheckFill } from "react-icons/bs";

import NotAvailable from "components/misc/NotAvailable";
import {
  IconWithText,
  IconContainer,
  Text,
} from "pages/public/sections/ServiceCarousel";
import cartSlice from "store/slices/cart";
import { toast } from "react-toastify";
import { useLoggedIn } from "store/slices/auth";
import { redirectToLogin } from "utils/helpers";

const ListPackage = ({ packages, isAdminView = false }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const cartPackages = useSelector((state) => state.cart.packages);
  const [viewPackageID, setViewPackageID] = useState([]);
  const isLoggedIn = useLoggedIn();

  const setupQuickView = (packageID) => {
    if (viewPackageID.includes(packageID)) {
      setViewPackageID(viewPackageID.filter((id) => id !== packageID));
    } else {
      setViewPackageID([...viewPackageID, packageID]);
    }
    // setViewPackage(true);
  };

  const addPackageToCart = (packageID) => {
    // console.log(packageID);
    if (isLoggedIn) {
      if (cartPackages.includes(packageID)) {
        toast("Item Already Added", { autoClose: 1000 });
      } else {
        dispatch(
          cartSlice.actions.setCartPackages({
            packages: [packageID, ...cartPackages],
          })
        );
        toast.success("Service Package Added", { autoClose: 1000 });
      }
    } else {
      redirectToLogin(location.pathname);
    }
  };

  // cartPackages && console.log(cartPackages);

  return (
    packages &&
    (packages.length > 0 ? (
      packages.map((packageItem, index) => (
        <div
          key={index}
          css={[
            tw`flex flex-col justify-center items-stretch`,
            index + 1 < packages.length && tw`mb-3`,
          ]}
        >
          <div tw="relative flex flex-col max-w-xs md:max-w-3xl xl:max-w-4xl mx-auto border border-white bg-white hover:shadow-2xl hover:scale-95 transition-all transform duration-500 rounded-xl shadow-lg p-3">
            <div tw="relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0">
              <div tw="w-full md:w-1/3 xl:w-5/12 bg-white grid place-items-center">
                <img src={packageItem.thumbnail} alt={packageItem.title} />
              </div>
              <div tw="w-full min-h-full bg-white flex flex-col space-y-2 p-2">
                <div tw="flex justify-between items-center md:pl-3">
                  <p tw="hidden sm:block text-gray-500 font-medium text-sm sm:text-base">
                    Incl. {packageItem.plans.length} Service Plans
                  </p>
                  {/* <div tw="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    tw="h-5 w-5 text-yellow-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                  <p tw="text-gray-600 font-bold text-sm ml-1">
                    4.96
                    <span tw="text-gray-500 font-normal">
                      (76 reviews)
                    </span>
                  </p>
                </div>
                <div tw="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    tw="h-5 w-5 text-pink-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div> */}
                  <Group tw="justify-between sm:justify-end w-full sm:w-auto">
                    <Button
                      compact
                      rightIcon={
                        viewPackageID.includes(packageItem.id) ? (
                          <AiFillEyeInvisible />
                        ) : (
                          <AiFillEye />
                        )
                      }
                      onClick={() => setupQuickView(packageItem.id)}
                      css={[
                        tw`bg-gray-200 px-3 xl:px-4 rounded-full text-xs xl:text-sm font-medium text-gray-800 hover:text-gray-100 cursor-pointer`,
                        viewPackageID.includes(packageItem.id)
                          ? tw`hover:bg-red-500`
                          : tw`hover:bg-yellow-500`,
                      ]}
                    >
                      {viewPackageID.includes(packageItem.id)
                        ? "See Less"
                        : "Quick View"}
                    </Button>

                    {!isAdminView && (
                      <ActionIcon
                        onClick={() => addPackageToCart(packageItem.id)}
                        variant="hover"
                        color="yellow"
                      >
                        {cartPackages.includes(packageItem.id) ? (
                          <BsFillCartCheckFill fontSize={22} />
                        ) : (
                          <BsCartPlus fontSize={22} />
                        )}
                      </ActionIcon>
                    )}
                  </Group>
                </div>
                <Link
                  to={packageItem.preview_page_url}
                  tw="font-black text-secondary-500 hover:text-yellow md:text-3xl text-xl"
                >
                  {packageItem.name}
                </Link>
                <p tw="xl:text-lg text-gray-500 text-base line-clamp-3 sm:line-clamp-4 mb-3">
                  {packageItem.description}
                </p>
                <div
                  style={{ marginTop: "auto" }}
                  tw="flex items-center justify-between pt-1"
                >
                  <IconWithText tw="flex-1">
                    <IconContainer tw="bg-gray-300">
                      <GrUserWorker />
                    </IconContainer>
                    <Text tw="xl:text-base">{packageItem.provider}</Text>
                  </IconWithText>
                  <Avatar
                    src={packageItem.location.flag_url}
                    color="orange"
                    radius="xl"
                    size="sm"
                  >
                    {packageItem.location.name}
                  </Avatar>
                  <p tw="text-lg font-bold text-primary-500 ml-2">
                    {packageItem.currency} {packageItem.price}
                    {/* <span tw="font-normal text-gray-600 text-base">
                  /night
                </span> */}
                  </p>
                </div>
              </div>
            </div>

            <Transition
              mounted={viewPackageID.includes(packageItem.id)}
              transition="slide-down"
              duration={400}
              timingFunction="ease"
            >
              {(styles) => (
                <div style={styles} tw="sm:px-3">
                  <Divider variant="dotted" my="xs" />
                  <span tw="text-xl font-bold">
                    Service Plans ({packageItem.plans.length})
                  </span>

                  <ViewPlans packageItem={packageItem} />
                </div>
              )}
            </Transition>
          </div>
        </div>
      ))
    ) : (
      <NotAvailable label="No packages created yet!" />
    ))
  );
};

export const ViewPlans = ({
  packageItem,
  isDark = false,
  isCartView = false,
}) => (
  <div tw="w-full flex flex-row flex-wrap my-3">
    {packageItem.plans.map((planItem, index) => (
      <div
        key={index}
        css={[
          tw`w-full md:w-1/2 flex items-center`,
          index + 1 !== packageItem.plans.length && tw`mb-3`,
          isDark && tw`flex-col md:flex-row`,
          isCartView && tw`flex-col`,
        ]}
      >
        <img
          tw="object-cover w-16 h-16 rounded-lg xl:w-20 xl:h-20"
          src={planItem.plan.service.thumbnail}
          alt={planItem.plan.service.title}
        />

        <div tw="ml-4">
          <h3
            css={[
              tw`text-base font-semibold xl:text-lg`,
              isCartView && tw`text-center`,
            ]}
          >
            {planItem.plan.service.title}
          </h3>

          <dl
            css={[
              tw`mt-1 space-y-1 text-sm`,
              isDark ? tw`text-gray-300` : tw`text-primary-500`,
            ]}
          >
            <div>
              <dt tw="inline mr-1">Plan:</dt>
              <dd tw="inline font-medium xl:text-base">
                {planItem?.plan?.title ?? "N/A"}
              </dd>
            </div>
            <div>
              <dt tw="inline mr-1">Price:</dt>
              <dd tw="inline font-medium xl:text-base">
                {planItem.plan.currency} {planItem.plan.price}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    ))}
  </div>
);

export default ListPackage;
