import React, { useState, useEffect } from "react";

import tw from "twin.macro";
import styled from "styled-components";
import {
  Button,
  MultiSelect,
  Stepper,
  Group,
  Checkbox,
  ActionIcon,
  Image,
  RadioGroup,
  Radio,
  Select,
} from "@mantine/core";
import { Dropzone, FileItem } from "@dropzone-ui/react";
import { FormikProvider, FieldArray, ErrorMessage, Field } from "formik";
import useSWR from "swr";
import { toast } from "react-toastify";
import { BsTrash } from "react-icons/bs";

import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { ErrorMsg } from "components/misc/FormLayout";
import ConfirmApplication from "./ConfirmApplication";
import { fetcher } from "utils/axios";
import SuccessSVG from "images/success.svg";
import FailedSVG from "images/failed.svg";

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input:not([type="radio"]),
  textarea {
    ${tw`w-full bg-transparent text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
  label {
    ${tw`text-gray-100`}
  }
`;

export const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
export const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`;

const ApplicationForm = ({
  formik,
  profileType,
  setValidateAttachment,
  profileSubmitted,
  cancel,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [allowNext, setAllowNext] = useState(false);
  const { validateForm } = formik;
  const [categoryData, setCategoryData] = useState(null);
  const [partnerData, setPartnerData] = useState(null);
  const formInitialURL = "/profiles/application-form-initial/";
  const { data: initialData, error: getInitialErr } = useSWR(
    formInitialURL,
    fetcher
  );

  useEffect(() => {
    if (!initialData) return;
    // console.log("rendered");
    let options = [];
    let kox_partners = [];
    initialData.categories.forEach((item) => {
      // console.log(option)
      options.push({
        label: item.title,
        value: item.id.toString(),
      });
    });
    initialData.kox_partners.forEach((item) => {
      // console.log(option)
      kox_partners.push({
        label: `${item.username} - ${item.email}`,
        value: item.id.toString(),
      });
    });
    // console.log(activeOptions);
    setCategoryData(options);
    setPartnerData(kox_partners);
  }, [initialData]);

  useEffect(() => {
    validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        setAllowNext(true);
      } else {
        setAllowNext(false);
      }
    });
  }, [validateForm, formik.values, activeStep]);

  useEffect(() => {
    if (profileSubmitted) setActiveStep(3);
  }, [profileSubmitted]);

  getInitialErr && toast.error("Couldn't load initial data!");

  const nextStep = () => {
    setActiveStep((current) => (current < 3 ? current + 1 : current));
    if (activeStep - 1 === 1 || activeStep + 1 === 1) {
      setValidateAttachment(true);
    }
  };
  const prevStep = () => {
    setActiveStep((current) => (current > 0 ? current - 1 : current));
    if (activeStep - 1 === 0) {
      setValidateAttachment(false);
    }
  };

  return (
    <FormContainer>
      <div tw="mx-auto max-w-4xl">
        <h2>
          Apply for{" "}
          {profileType === "service"
            ? "Service Provicer"
            : profileType === "consultant"
            ? "Consultant Profile"
            : "Data Provider"}
        </h2>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Stepper
          active={activeStep}
          onStepClick={setActiveStep}
          breakpoint="sm"
          color="yellow"
        >
          <Stepper.Step
            label={<span tw="text-white">Primary Details</span>}
            description="Email, Info..."
            allowStepSelect={!profileSubmitted && activeStep > 0}
          >
            <TwoColumn>
              <Column>
                <InputContainer>
                  <Label htmlFor="name-input">
                    Your Legal Name <span tw="text-gray-500">/ Identifier</span>
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    placeholder="E.g. John Doe"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <ErrorMsg>{formik.errors.name} </ErrorMsg>
                  )}
                </InputContainer>
                <InputContainer>
                  <Label htmlFor="email-input">Your Work Email</Label>
                  <Input
                    id="email-input"
                    type="email"
                    name="email"
                    placeholder="E.g. john@mail.com"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <ErrorMsg>{formik.errors.email} </ErrorMsg>
                  )}
                </InputContainer>
                <InputContainer>
                  <Label htmlFor="email-input">Your Work Number</Label>
                  <Input
                    type="tel"
                    name="contact_number"
                    placeholder="E.g. (9819291012)"
                    value={formik.values.contact_number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.contact_number &&
                    formik.errors.contact_number && (
                      <ErrorMsg>{formik.errors.contact_number} </ErrorMsg>
                    )}
                </InputContainer>
              </Column>
              <Column>
                <InputContainer tw="flex-1">
                  <Label htmlFor="info">Your Message</Label>
                  <TextArea
                    name="info"
                    placeholder="Please tell us what exactly you would offer..."
                    value={formik.values.info}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.info && formik.errors.info && (
                    <ErrorMsg>{formik.errors.info} </ErrorMsg>
                  )}
                </InputContainer>
              </Column>
            </TwoColumn>

            <TwoColumn>
              <Column>
                <InputContainer>
                  <Label htmlFor="location">Location</Label>
                  <Input
                    type="text"
                    name="location"
                    placeholder="From where you're operating"
                    value={formik.values.location}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.location && formik.errors.location && (
                    <ErrorMsg>{formik.errors.location} </ErrorMsg>
                  )}
                </InputContainer>
                {profileType !== "consultant" && (
                  <InputContainer>
                    <Label htmlFor="categories">Related Categories</Label>
                    {categoryData && (
                      <MultiSelect
                        name="categories"
                        tw="mt-3"
                        styles={{
                          defaultVariant: {
                            background: "transparent",
                            borderBottom: "2px solid white",
                            outlineColor: "pink",
                          },
                        }}
                        data={categoryData}
                        placeholder="Please select the ones that concern you"
                        searchable
                        nothingFound="Nothing found"
                        value={formik.values.categories}
                        onChange={(value) =>
                          formik.setFieldValue("categories", value)
                        }
                        onBlur={formik.handleBlur}
                      />
                    )}

                    {formik.touched.categories && formik.errors.categories && (
                      <ErrorMsg>{formik.errors.categories} </ErrorMsg>
                    )}
                  </InputContainer>
                )}
                {profileType === "consultant" &&
                  formik.values.consultant_type === "kox_partner" && (
                    <InputContainer tw="flex-1">
                      <Label htmlFor="consultant_type">Associated With</Label>
                      {partnerData && (
                        <Select
                          name="associated_with"
                          tw="mt-3"
                          styles={{
                            defaultVariant: {
                              background: "transparent",
                              borderBottom: "2px solid white",
                              outlineColor: "pink",
                            },
                          }}
                          data={partnerData}
                          placeholder="Please select the partner you want to associate with"
                          searchable
                          nothingFound="Nothing found"
                          value={formik.values.associated_with}
                          onChange={(value) =>
                            formik.setFieldValue("associated_with", value)
                          }
                          onBlur={formik.handleBlur}
                        />
                      )}
                    </InputContainer>
                  )}
              </Column>

              <Column>
                {profileType !== "consultant" && (
                  <InputContainer tw="flex-1">
                    <Label htmlFor="extra-categories">
                      Suggest Categories{" "}
                      <span tw="text-gray-500">(Optional)</span>
                    </Label>
                    <TextArea
                      name="suggested_categories"
                      placeholder="If you'd like to suggest more categories..."
                      value={formik.values.suggested_categories}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </InputContainer>
                )}
                {profileType === "consultant" && (
                  <InputContainer tw="flex-1">
                    <Label htmlFor="consultant_type">Profile Type</Label>
                    <RadioGroup
                      tw="mt-2"
                      color="orange"
                      description="You will be providing your services for ..."
                      value={formik.values.consultant_type}
                      onChange={(value) =>
                        formik.setFieldValue("consultant_type", value)
                      }
                      required
                    >
                      <Radio value="for_all" label="For All" color={"white"} />
                      <Radio value="kox_partner" label="KOX Partner" />
                      <Radio value="kox_only" label="KOX Only" />
                    </RadioGroup>
                  </InputContainer>
                )}
              </Column>
            </TwoColumn>

            <Checkbox
              name="is_company"
              tw="my-3"
              color="red"
              label={
                <span tw="text-base text-gray-lightest">
                  I am not an individual! I am an agency / organization /
                  institution.
                </span>
              }
              checked={formik.values.is_company}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Stepper.Step>
          <Stepper.Step
            label={<span tw="text-white">Attachments</span>}
            description="Legal Documents"
            allowStepSelect={!profileSubmitted && activeStep > 1}
          >
            <p tw="text-center">
              Legal and General Documents to verify your application
            </p>
            <FormikProvider value={formik}>
              <FieldArray
                name="attachments"
                render={(arrayHelpers) => (
                  <div tw="my-5">
                    <Group position="left" my="lg">
                      <Button
                        variant="light"
                        color="yellow"
                        onClick={() =>
                          arrayHelpers.push({
                            label: "",
                            fileItem: [],
                            remarks: "",
                          })
                        }
                      >
                        Add another File
                      </Button>
                    </Group>
                    {formik.values.attachments.map((attachment, index) => (
                      <Group key={index} mt="xs" className="align-items-center">
                        <div className="flex-1">
                          <div tw="py-2">
                            <Field
                              name={`attachments.${index}.label`}
                              placeholder={`File Label`}
                              type="text"
                              className="form-control"
                              // value={formik.values.options[index].text}
                            />
                            <ErrorMessage
                              name={`attachments.${index}.label`}
                              component="div"
                              className="field-error text-red-600"
                            />
                          </div>
                          <div tw="py-2">
                            <Field
                              name={`attachments.${index}.remarks`}
                              placeholder={`Remarks`}
                              as="textarea"
                              className="form-control"
                              rows="4"
                              // value={formik.values.options[index].text}
                            />
                            <ErrorMessage
                              name={`attachments.${index}.remarks`}
                              component="div"
                              className="field-error text-red-600"
                            />
                          </div>
                        </div>
                        <div className="flex-1">
                          <Dropzone
                            //   onDrop={(files) => console.log(files)}
                            onChange={(files) =>
                              formik.setFieldValue(
                                `attachments.${index}.fileItem`,
                                files
                              )
                            }
                            behaviour="replace"
                            multiple={false}
                            maxHeight="200px"
                            label="Select or drop your file here"
                            value={attachment.fileItem}
                            maxFiles={1}
                          >
                            {attachment.fileItem.length > 0 &&
                              attachment.fileItem[0] && (
                                <FileItem {...attachment.fileItem[0]} preview />
                              )}
                          </Dropzone>
                          {/* {console.log(attachment.fileItem)} */}
                        </div>
                        <ActionIcon
                          color="red"
                          variant="outline"
                          onClick={() =>
                            formik.values.attachments.length > 1 &&
                            arrayHelpers.remove(index)
                          }
                        >
                          <BsTrash />
                        </ActionIcon>
                      </Group>
                    ))}
                  </div>
                )}
              ></FieldArray>
            </FormikProvider>
          </Stepper.Step>

          <Stepper.Step
            label={<span tw="text-white">Confirmation</span>}
            description="Verify Application"
            allowStepSelect={!profileSubmitted && activeStep > 2}
          >
            <ConfirmApplication
              application={formik.values}
              categoryData={categoryData}
              profileType={profileType}
              partnerData={partnerData}
            />
            <Group position="center">
              <SubmitButton disabled={!allowNext} type="submit" value="Submit">
                Submit
              </SubmitButton>
            </Group>
          </Stepper.Step>
          <Stepper.Completed>
            <div tw="w-full lg:w-2/3 mx-auto flex mobile:flex-col items-center justify-center">
              <div tw="w-1/2 mobile:w-full p-5 mobile:px-0">
                <Image
                  width={200}
                  height={200}
                  src={profileSubmitted ? SuccessSVG : FailedSVG}
                  alt="Default Thumbnail placeholder"
                  withPlaceholder
                />
              </div>
              <p tw="font-semibold">
                {profileSubmitted ? (
                  "Congratulations, your application has been submitted. We'll get back to you soon!"
                ) : (
                  <>
                    <span>Sorry, we could not process your application.</span>{" "}
                    <br /> <span>Please try again later!</span>
                  </>
                )}
              </p>
            </div>
          </Stepper.Completed>
        </Stepper>

        <Group mt="xl" tw="justify-between">
          {activeStep > 0 && (
            <Button
              disabled={profileSubmitted}
              variant="subtle"
              color="gray"
              onClick={prevStep}
            >
              Back
            </Button>
          )}

          {activeStep < 2 && (
            <Button disabled={!allowNext} color="orange" onClick={nextStep}>
              Next step
            </Button>
          )}

          <Button
            onClick={() => cancel()}
            type="button"
            variant="subtle"
            color="red"
            tw="mx-2"
          >
            {profileSubmitted ? "Close" : "Cancel"}
          </Button>
        </Group>
      </form>
      <SvgDotPattern1 />
    </FormContainer>
  );
};

export default ApplicationForm;
