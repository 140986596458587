import { lazy } from "react";
// import { Route } from "react-router-dom";

// import Hoc from "./HOC";

// use lazy for better code splitting, a.k.a. load faster
const Login = lazy(() => import("../pages/Login"));
const Signup = lazy(() => import("../pages/Signup"));
const ActivateAccount = lazy(() => import("../pages/ActivateAccount"));
const ResetPassword = lazy(() => import("../components/core/ResetPassword"));
const ConfirmPasswordReset = lazy(() =>
  import("../components/core/ConfirmPasswordReset")
);

const authRoutes = [
  {
    path: "/login", // the url
    component: Login, // view rendered
  },
  {
    path: "/register", // the url
    component: Signup, // view rendered
  },
  {
    path: "/reset-password", // the url
    component: ResetPassword, // view rendered
  },
  {
    path: "/activate-account/:uid/:token", // the url
    component: ActivateAccount, // view rendered
  },
  {
    path: "/confirm-password-reset/:uid/:token", // the url
    component: ConfirmPasswordReset, // view rendered
  },
];

export default authRoutes;

// const AuthRoutes = () => {
//   return (
//     // Instead of div a Higher Order Component is being used so the styles are not affected
//     <Hoc>
//       <Route exact path="/login">
//         <Login />
//       </Route>
//       <Route exact path="/register" component={Signup}></Route>
//       <Route
//         exact
//         path="/activate-account/:uid/:token"
//         component={ActivateAccount}
//       ></Route>
//     </Hoc>
//     // the higher order component is not rendered but only the routes
//   );
// };
