import { Link } from "react-router-dom";

import tw from "twin.macro";
import useSWR from "swr";
import { ActionIcon, Group, Indicator } from "@mantine/core";
import { IoNotificationsOutline } from "react-icons/io5";

import UserDropdown from "./UserDropdown";
import { useToggle } from "../provider/context";
import { fetcher } from "utils/axios";
// import { withClientRole } from "utils/withRole";

const Header = tw.header`h-20 items-center relative z-10`;

export default function TopNavigation() {
  const { toggle } = useToggle();
  const { data, error } = useSWR("/core/top-navigation/", fetcher);

  error && console.log(error);
  // const ClientLink = withClientRole(Link);
  return (
    <Header>
      <div tw="flex items-center flex-col h-full justify-center mx-auto relative px-3 text-white z-10">
        <div tw="flex items-center pl-1 relative w-full sm:ml-0 sm:pr-2 lg:pl-5 lg:pr-10">
          <div tw="flex h-full items-center relative w-12" className="group">
            <button
              type="button"
              aria-expanded="false"
              aria-label="Toggle sidenav"
              onClick={toggle}
              tw="text-4xl text-white focus:outline-none"
            >
              &#8801;
            </button>
          </div>
          <div tw="opacity-0 container flex left-0 relative">
            <div
              tw="flex items-center ml-8 relative w-full md:flex lg:w-72"
              className="group"
            >
              <div
                tw="absolute cursor-pointer flex items-center justify-center h-10 p-3 pr-2 text-gray-500 text-sm uppercase w-auto sm:hidden"
                className="block"
              >
                <svg
                  fill="none"
                  tw="h-5 relative w-5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <svg
                tw="absolute fill-current h-4 hidden left-0 ml-4 pointer-events-none text-gray-500 w-4 group-hover:text-gray-400 sm:block"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
              </svg>
              <input
                type="text"
                tw="bg-gray-800 block leading-normal pl-10 py-2 pr-4 ring-opacity-75 rounded-2xl text-gray-400 w-full focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Search"
              />
            </div>
          </div>
          <div tw="flex items-center justify-end mr-5 p-1 relative w-full sm:mr-0 sm:right-auto">
            {/* <a href="/app" tw="block pr-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                tw="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
                />
              </svg>
            </a>
            <a href="/app" tw="block pr-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                tw="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </a> */}
            <Group mr="sm">
              <Link to="/notifications">
                <Indicator
                  inline
                  label={data?.notifications_count}
                  color="yellow"
                  size={16}
                >
                  <ActionIcon variant="transparent">
                    <IoNotificationsOutline fontSize={30} color="white" />
                  </ActionIcon>
                </Indicator>
              </Link>
            </Group>
          </div>
          <UserDropdown />
        </div>
      </div>
    </Header>
  );
}
