import { lazy } from "react";

// use lazy for better code splitting, a.k.a. load faster
const ServiceCategory = lazy(() =>
  import("pages/serviceCategory/ServiceCategory")
);
const CategoryDetail = lazy(() =>
  import("pages/serviceCategory/CategoryDetail")
);
const SubCategories = lazy(() => import("pages/serviceCategory/SubCategory"));

const serviceCategoryRoutes = [
  {
    path: "/service-category", // the url
    component: ServiceCategory, // view rendered
    title: "Service Categories",
    exact: true,
    permission: ["Admin"],
  },
  {
    path: "/service-category/:slug", // the url
    component: CategoryDetail, // view rendered
    title: "Category Detail",
    exact: true,
  },
  {
    path: "/service-category/:catSlug/subcategories/", // the url
    component: SubCategories, // view rendered
    title: "Sub Categories",
  },
];

export default serviceCategoryRoutes;
