// import HomeIcon from "./icons/home";
// import StatusIcon from "./icons/status";
// import CreditsIcon from "./icons/credits";
// import ArchivesIcon from "./icons/archives";
// import SettingsIcon from "./icons/settings";
// import DocumentationIcon from "./icons/documentation";
// import { RiHome2Fill } from "react-icons/ri";
import {
  AiOutlineHome,
  AiFillProject,
  // AiFillDatabase,
  // AiFillBulb,
  // AiOutlineSetting,
} from "react-icons/ai";
import {
  BiCategory,
  BiMapPin,
  BiGridSmall,
  BiPurchaseTag,
  BiPackage,
} from "react-icons/bi";
import { CgOrganisation } from "react-icons/cg";
import { RiTeamLine } from "react-icons/ri";
import {
  FaUsers,
  FaToolbox,
  FaUserTie,
  FaBloggerB,
  FaBoxes,
  // FaNetworkWired,
} from "react-icons/fa";
import {
  MdSwitchAccount,
  MdLocationPin,
  MdAttachMoney,
  MdOutlineContactPhone,
  MdOutlinedFlag,
  // MdOutlineAutoAwesomeMotion,
  MdMiscellaneousServices,
} from "react-icons/md";
import { SiZoom } from "react-icons/si";
import { GrContactInfo } from "react-icons/gr";
import { ImUserTie } from "react-icons/im";

const data = [
  {
    title: "Home",
    icon: <AiOutlineHome size="25px" />,
    link: "/app",
  },
  {
    title: "Users",
    icon: <FaUsers size="25px" />,
    link: "/manage-users",
    roles: ["Admin"],
  },
  {
    title: "Profiles",
    icon: <MdSwitchAccount size="25px" />,
    link: "/profile-application",
    roles: ["Client"],
  },
  {
    title: "Category",
    icon: <BiCategory size="25px" />,
    link: "/service-category",
    roles: ["Admin"],
  },
  {
    title: "Services",
    icon: <FaToolbox size="23px" />,
    link: "/services",
    roles: ["Admin", "Service Provider"],
  },
  {
    title: "Projects",
    icon: <AiFillProject size="23px" />,
    link: "/manage-projects",
    roles: ["Client", "Admin", "Service Provider"],
  },
  {
    title: "Institutions",
    icon: <CgOrganisation size="25px" />,
    link: "/institution-application",
    roles: ["Client"],
  },
  {
    title: "Institutions",
    icon: <CgOrganisation size="25px" />,
    link: "/manage-institutions",
    roles: ["Admin"],
  },
  {
    title: "Blogs",
    icon: <FaBloggerB size="23px" />,
    link: "/blog",
    roles: ["Admin"],
  },
  {
    title: "Project Invitations",
    icon: <RiTeamLine size="25px" />,
    link: "/manage-invitations",
    roles: ["Client"],
  },
  {
    title: "Authorization", // Zoom Authorization
    icon: <SiZoom size="25px" />,
    link: "/admin-zoom-auth",
    roles: ["Admin"],
  },
];

export const extraItems = [
  {
    label: "Purchases",
    icon: <FaBoxes fontSize={25} />,
    links: [
      {
        title: "My Purchases",
        icon: <BiPurchaseTag size="23px" />,
        url: "/my-purchases",
        roles: ["Client"],
      },

      {
        title: "Manage Purchases",
        icon: <BiGridSmall size="23px" />,
        url: "/manage-purchases",
        roles: ["Admin"],
      },
      {
        title: "For my Services",
        icon: <MdMiscellaneousServices size="23px" />,
        url: "/manage-purchased-services",
        roles: ["Service Provider"],
      },
      {
        title: "For my Packages",
        icon: <BiPackage size="23px" />,
        url: "/manage-package-purchases",
        roles: ["Service Provider"],
      },
    ],
  },
  {
    label: "User Profiles",
    icon: <MdSwitchAccount fontSize={25} />,
    links: [
      {
        title: "Service Provider",
        icon: <FaUserTie size="23px" />,
        url: "/manage-profiles/service",
        roles: ["Admin"],
      },
      {
        title: "Consultant Profile",
        icon: <ImUserTie size="23px" />,
        url: "/manage-profiles/consultant",
        // roles: ["Admin", "Service Provider"],
      },
    ],
    roles: ["Admin", "Service Provider"],
  },
  {
    label: "Enquiries",
    icon: <MdOutlineContactPhone fontSize={25} />,
    links: [
      {
        title: "Project Reports",
        icon: <MdOutlinedFlag size="23px" />,
        url: "/projects/reports",
      },
      {
        title: "General Enquiries",
        icon: <GrContactInfo size="23px" />,
        url: "/enquiries/contacts",
      },
    ],
    roles: ["Admin"],
  },
  // {
  //   label: "Projects",
  //   icon: <FaNetworkWired fontSize={25} />,
  //   links: [
  //     {
  //       title: "My Projects",
  //       icon: <AiFillProject size="23px" />,
  //       url: "/my-projects",
  //     },
  //   ],
  //   roles: ["Client"],
  // },
  {
    label: "Locations",
    icon: <MdLocationPin fontSize={25} />,
    links: [
      {
        title: "Countries",
        icon: <BiMapPin size="23px" />,
        url: "/manage-countries",
      },
      {
        title: "Currencies",
        icon: <MdAttachMoney size="23px" />,
        url: "/manage-currencies",
      },
    ],
    roles: ["Admin"],
  },
];

export default data;
