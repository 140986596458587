import { lazy } from "react";

// use lazy for better code splitting, a.k.a. load faster
const ManagePurchases = lazy(() =>
  import("pages/purchases/admin/ManagePurchase")
);
const PurchaseHistory = lazy(() =>
  import("pages/purchases/client/PurchaseHistory")
);
const ManageServicePurchases = lazy(() =>
  import("pages/purchases/services/ManageServicePurchases")
);
const ManagePackagePurchases = lazy(() =>
  import("pages/purchases/packages/ManagePackagePurchases")
);
const SPServices = lazy(() => import("pages/purchases/services/SPServices"));
const ManageProjects = lazy(() =>
  import("pages/purchases/projects/ManageProjects")
);
const PurchasePackages = lazy(() =>
  import("pages/purchases/admin/PurchasePackages")
);

const purchaseRoutes = [
  {
    path: "/manage-purchases", // the url
    component: ManagePurchases, // view rendered
    title: "Manage Purchases",
    exact: true,
    permission: ["Admin"],
  },
  {
    path: "/manage-purchased-services", // the url
    component: SPServices, // view rendered
    title: "Manage Service Purchases",
    exact: true,
    permission: ["Service Provider"],
  },
  {
    path: "/manage-package-purchases", // the url
    component: ManagePackagePurchases, // view rendered
    title: "Manage Package Purchases",
    exact: true,
    permission: ["Service Provider"],
  },
  {
    path: "/my-purchases", // the url
    component: PurchaseHistory, // view rendered
    title: "My Purchases",
    exact: true,
    permission: ["Client"],
  },
  {
    path: "/purchase/:id/manage-service/", // the url
    component: ManageServicePurchases, // view rendered
    title: "Purchase Services",
    exact: true,
    permission: ["Admin"],
  },
  {
    path: "/purchase/:id/packages/", // the url
    component: PurchasePackages, // view rendered
    title: "Purchase Packages",
    exact: true,
    permission: ["Admin"],
  },
  {
    path: "/manage-projects", // the url
    component: ManageProjects, // view rendered
    title: "Manage Projects",
    exact: true,
    permission: ["Admin", "Service Provider", "Client"],
  },
];

export default purchaseRoutes;
