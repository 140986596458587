import { lazy } from "react";

// use lazy for better code splitting, a.k.a. load faster
const Services = lazy(() => import("pages/service/"));
const ServiceDashboard = lazy(() =>
  import("pages/service/dashboard/ServiceDashboard")
);
const ManageEnquiries = lazy(() =>
  import("pages/service/enquiries/ManageEnquiries")
);
const ManageProcedure = lazy(() =>
  import("pages/service/procedure/ManageProcedure")
);
const Plan = lazy(() => import("pages/service/plans/index"));
// const ManageDuration = lazy(() => import("pages/service/plans/ManageDuration"));
const ServiceDetail = lazy(() => import("pages/service/detail/ServiceDetail"));

const serviceRoutes = [
  {
    path: "/services", // the url
    component: Services, // view rendered
    title: "Services",
    exact: true,
    permission: ["Admin", "Service Provider"],
  },
  {
    path: "/services/:slug/dashboard", // the url
    component: ServiceDashboard, // view rendered
    title: "Service Dashboard",
    exact: true,
    permission: ["Admin", "Service Provider"],
  },
  {
    path: "/services/:slug/plan", // the url
    component: Plan, // view rendered
    title: "Service Plans",
    exact: true,
    permission: ["Admin", "Service Provider"],
  },
  // {
  //   path: "/services/:serviceSlug/:slug/procedure-duration", // the url
  //   component: ManageDuration, // view rendered
  //   title: "Service Procedure Duration",
  //   exact: true,
  //   permission: ["Admin", "Service Provider"],
  // },
  {
    path: "/services/:slug/procedure", // the url
    component: ManageProcedure, // view rendered
    title: "Manage Procedures",
    exact: true,
    permission: ["Admin", "Service Provider"],
  },
  {
    path: "/services/:slug/enquiries", // the url
    component: ManageEnquiries, // view rendered
    title: "Manage Enquiries",
    exact: true,
    permission: ["Admin", "Service Provider"],
  },
  {
    path: "/services/:slug/detail", // the url
    component: ServiceDetail, // view rendered
    title: "Service Detail",
    exact: true,
    // permission: ["Admin", "Service Provider"],
  },
];

export default serviceRoutes;
