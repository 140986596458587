import React from "react";

import tw from "twin.macro";
import { FileItem } from "@dropzone-ui/react";
import { FcRightDown2 } from "react-icons/fc";
import {
  MdPermIdentity,
  MdAlternateEmail,
  MdOutlineLocalPhone,
  MdLocationPin,
  MdTableChart,
  MdInfo,
} from "react-icons/md";
import { Kbd } from "@mantine/core";

const InfoItemWrapper = tw.div`w-full md:w-1/2 md:pr-5 md:py-3`;

const InfoItem = ({ label, text, icon }) => (
  <InfoItemWrapper>
    <div tw="flex relative pb-12">
      <div tw="h-full w-10 absolute inset-0 flex items-center justify-center">
        <div tw="h-1/2 mobile:h-full w-1 bg-gray-200 pointer-events-none"></div>
      </div>
      <div tw="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
        {icon}
      </div>
      <div tw="flex-grow pl-4">
        <h3 tw="font-medium text-sm mb-1 tracking-wider">{label}</h3>
        <div tw="leading-relaxed font-bold whitespace-normal">{text}</div>
      </div>
    </div>
  </InfoItemWrapper>
);

const ConfirmApplication = ({
  application,
  categoryData,
  profileType,
  partnerData,
}) => {
  //   console.log(application);
  const getCategoryTitle = (itemID) => {
    let categoryTitle = "";
    categoryData.forEach((category) => {
      if (category.value === itemID.toString()) {
        categoryTitle = category.label;
      }
    });
    return categoryTitle;
  };

  const getPartnerInfo = (partnerId) => {
    let partnerInfo = "";
    partnerData.forEach((partner) => {
      if (partner.value === partnerId.toString()) {
        partnerInfo = partner.label;
      }
    });
    return partnerInfo;
  };

  const extractCategories = () => {
    return (
      <>
        <p tw="text-gray-400">
          <span tw="text-sm">Requested:</span>{" "}
          {application?.categories.map((item, index) => (
            <Kbd key={index} tw="mx-1">
              {getCategoryTitle(item)}
            </Kbd>
          ))}
        </p>{" "}
        <p tw="mt-3">
          <span tw="text-sm text-gray-400 mr-2">Suggested: </span>
          <span>
            {application.suggested_categories === ""
              ? "None"
              : application.suggested_categories}
          </span>
        </p>
      </>
    );
  };

  const extractProfileTypeInfo = () => {
    return (
      <>
        <p tw="text-gray-400">
          <span tw="text-sm">Consultant Profile Type:</span>{" "}
          <span>
            {application.consultant_type.replace("_", " ").toUpperCase()}
          </span>
        </p>{" "}
        {application.consultant_type === "kox_partner" && (
          <p tw="mt-3">
            <span tw="text-sm text-gray-400 mr-2">Associated With: </span>
            <span>{getPartnerInfo(application.associated_with)}</span>
          </p>
        )}
      </>
    );
  };

  return (
    <>
      <section tw="font-normal">
        <div tw="container px-5 mobile:pl-0 mt-4 mx-auto">
          <p tw="flex items-center text-xl font-bold mb-5">
            <span tw="mr-2">Primary Details</span> <FcRightDown2 />
          </p>

          <div tw="flex flex-wrap w-full">
            <InfoItem
              label="Legal Name / Identifier"
              text={application.name}
              icon={<MdPermIdentity />}
            />
            <InfoItem
              label="Work Email"
              text={application.email}
              icon={<MdAlternateEmail />}
            />
            <InfoItem
              label="Work Contact"
              text={application.contact_number}
              icon={<MdOutlineLocalPhone />}
            />
            <InfoItem
              label="Location"
              text={application.location}
              icon={<MdLocationPin />}
            />
            <InfoItem
              label="Information"
              text={application.info}
              icon={<MdInfo />}
            />
            {profileType !== "consultant" && (
              <InfoItem
                label="Categories"
                text={extractCategories()}
                icon={<MdTableChart />}
              />
            )}
            {profileType === "consultant" && (
              <InfoItem
                label="Profile"
                text={extractProfileTypeInfo()}
                icon={<MdTableChart />}
              />
            )}
          </div>
        </div>
      </section>
      <section tw="font-normal">
        <div tw="container px-5 mt-4 mx-auto">
          <p tw="flex items-center text-xl font-bold mb-10 mobile:mb-16">
            <span tw="mr-2">Attachments</span> <FcRightDown2 />
          </p>
          {application.attachments.length > 0 &&
            application.attachments.map((attachment, index) => (
              <div
                key={index}
                tw="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col"
              >
                <div tw="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center text-indigo-500 flex-shrink-0">
                  <FileItem {...attachment.fileItem[0]} preview />
                </div>
                <div tw="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                  <p tw="text-gray-400 text-lg font-semibold mb-2">
                    {attachment.label}
                  </p>

                  <p tw="text-gray-500 leading-relaxed text-base">
                    {attachment.remarks ? attachment.remarks : "No Remarks."}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </section>
    </>
  );
};

export default ConfirmApplication;
